import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Button, Col, Modal, Row, Table } from 'react-bootstrap';
import { toast } from 'react-toastify';
import styles from './GhStepDetails.module.scss';
import tickIcon from '../../assets/images/tickIcon.svg';
import crossIcon from '../../assets/images/crossIcon.svg';
import { dateFormat } from '../../utils/dateFormat';
import { NumberFormat } from '../../utils/currencyConverter';
import Accordion from 'react-bootstrap/Accordion'

interface ModalProps {
    show: boolean;
    onHide: () => void;
    policyDetails: any;
}

export const getCoverageDescription = (coverageName: string): string => {
    const coverageMap: { [key: string]: string } = {
      E: 'Employee Only',
      ESC: 'Employee, Spouse & Children',
      ESCP: 'Employee, Spouse, Children & Parents',
    };
  
    return coverageMap[coverageName] || 'Invalid coverage name';
  };

const GhStepDetails = ({ show, onHide, policyDetails }: ModalProps) => {

    console.log(policyDetails)

    const standardCover = policyDetails?.request_metadata?.locationBasedRiskAndCoverPackages?.[0]
        ?.standardCovers ?? [];

    let risks: any = [];

    policyDetails?.request_metadata?.locationBasedRiskAndCoverPackages?.forEach(
        (location: any) => {
            risks = risks.concat(location?.risks);
        },
    );

    const risksTotal =
        policyDetails?.insurance_type === "stock"
            ? risks
                ?.map((x: any) =>
                    [
                        'Raw Material',
                        'Stocks in Process',
                        'Finished Stocks',
                    ].includes(x?.riskName)
                        ? x?.sumInsured ?? 0
                        : 0,
                )
                ?.reduce((a: any, b: any) => a + b, 0) : risks
                    ?.map((x: any) =>
                        [
                            'Building Including Plinth & Foundation',
                            'Contents',
                            'Furniture Fixture & Fittings',
                            'Plant & Machinery',
                        ].includes(x?.riskName)
                            ? x?.sumInsured ?? 0
                            : 0,
                    )
                    ?.reduce((a: any, b: any) => a + b, 0);

    const handleModalClose = () => {
        onHide();
    };

    return (
        <Modal
            show={show}
            onHide={onHide}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title
                    id="contained-modal-title-vcenter"
                    className={styles.modalTitle}
                >
                    Step Details {' '}
                    {policyDetails?.purchasePolicyDetails?.cover?.riskName}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Accordion defaultActiveKey="0">
                    {
                        policyDetails?.stepNumber > 1 &&
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>Company Details </Accordion.Header>
                            <Accordion.Body>
                                <Table striped bordered hover>
                                    <thead>
                                        <tr>
                                            <th colSpan={4}>Company Name</th>
                                            <th colSpan={4}>Owner / User Name</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td colSpan={4}>
                                                {
                                                    // policyDetails?.request_metadata
                                                    //     ?.onBoardingData?.personalDetails?.company_name ?? "N/A"
                                                    policyDetails?.request_metadata?.personal_details.company_name  ?? "NA"
                                                }
                                            </td>
                                            <td colSpan={4}>
                                                {
                                                    policyDetails?.first_name 
                                                }
                                            </td>
                                        </tr>
                                        <tr>
                                            <th colSpan={4}>
                                                Contact Email
                                            </th>
                                            <th colSpan={4}>
                                                Contact Phone Number
                                            </th>
                                            {/* <th colSpan={2}>
                                                Entity Type
                                            </th>
                                            <th colSpan={2}>
                                                Construction Type
                                            </th> */}
                                        </tr>
                                        <tr>
                                            <td colSpan={4}>
                                                <a href={`mailto:${policyDetails?.email}`}>{policyDetails?.email}</a>
                                            </td>
                                            <td colSpan={4}>
                                                <a href={`tel:${policyDetails?.phone_number}`}>{policyDetails?.phone_number}</a>
                                            </td>
                                            {/* <td colSpan={2}>
                                                {
                                                    policyDetails?.request_metadata
                                                        ?.onBoardingData?.companyDetails?.company_type?.name
                                                }
                                            </td>
                                            <td colSpan={2}>
                                                {
                                                    policyDetails?.request_metadata?.locationBasedRiskAndCoverPackages?.[0]?.buildingRiskProfile?.constructionDetails?.puccaOrKutcha ?? "N/A"
                                                }
                                            </td> */}
                                        </tr>
                                        {/* <tr>
                                            <th colSpan={4}>
                                                Turnover
                                            </th>
                                            <th colSpan={4}>
                                                Funding Status
                                            </th>

                                        </tr> */}
                                        {/* <tr>
                                            <td colSpan={4}>
                                                {
                                                    policyDetails?.request_metadata
                                                        ?.onBoardingData?.companyDetails?.turnover?.name
                                                }
                                            </td>
                                            <td colSpan={4}>
                                                {
                                                    policyDetails?.request_metadata
                                                        ?.onBoardingData?.companyDetails?.is_companyfunded ? policyDetails?.request_metadata
                                                            ?.onBoardingData?.companyDetails?.fundingAmount : "Not Funded"
                                                }
                                            </td>
                                        </tr> */}
                                        <tr>
                                            <th colSpan={8}>
                                                Industry 
                                            </th>
                                            {/* <th colSpan={4}>
                                                Business Category
                                            </th> */}

                                        </tr>
                                        <tr>
                                            <td colSpan={8}>
                                                {
                                                     policyDetails?.request_metadata
                                                        ?.personal_details?.company_industry ?? "N/A"
                                                }
                                            </td>
                                            {/* <td colSpan={4}>
                                                {
                                                    policyDetails?.request_metadata
                                                        ?.personal_details?.company_industry ?? "N/A"
                                                }
                                            </td> */}
                                        </tr>
                                    </tbody>
                                </Table>
                            </Accordion.Body>
                        </Accordion.Item>
                    }
                       {
                        <Accordion.Item eventKey="1">
                            <Accordion.Header>Employee Details </Accordion.Header>
                            <Accordion.Body>
                                <Row style={{ padding: '20px' }}>
                                    {/* <h5>Coverage </h5> */  }           

                                    <Table bordered hover>
                                        <thead>
                                            <tr>
                                                <th colSpan={6}>Items </th>
                                                <th colSpan={2}>Count </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>    
                                                <td colSpan={6}
                                                >
                                                   Total Employee

                                                </td>
  
                                                <td
                                                    colSpan={2}
                                                >
                                                    { 
                                                     policyDetails?.request_metadata?.employee_details.totalEmployees ?? "0"
                                                    }
                                                </td>
                                            </tr>
                                            <tr>    
                                                <td colSpan={6}
                                                >
                                                   Total Spouse

                                                </td>
  
                                                <td
                                                    colSpan={2}
                                                >
                                                    { 
                                                     policyDetails?.request_metadata?.employee_details.totalSpouse ?? "0"
                                                    }
                                                </td>
                                            </tr>
                                            <tr>    
                                                <td colSpan={6}
                                                >
                                                   Total Children

                                                </td>
  
                                                <td
                                                    colSpan={2}
                                                >
                                                    { 
                                                     policyDetails?.request_metadata?.employee_details.totalChildren ?? "0"
                                                    }
                                                </td>
                                            </tr>
                                            <tr>    
                                                <td colSpan={6}
                                                >
                                                   Total Parents

                                                </td>
  
                                                <td
                                                    colSpan={2}
                                                >
                                                    { 
                                                     policyDetails?.request_metadata?.employee_details.totalParents ?? "0"
                                                    }
                                                </td>
                                            </tr>
                                            </tbody>    
                                          
                                        
                                    </Table>

                                    <Table bordered hover>
                                        <thead>
                                            <tr>
                                                <th colSpan={6}>Age Bands </th>
                                                <th colSpan={2}>Count </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>    
                                                <td colSpan={6}
                                                >
                                                   0 - 18

                                                </td>
  
                                                <td
                                                    colSpan={2}
                                                >
                                                    { 
                                                     policyDetails?.request_metadata?.employee_details?.["0-18"] ?? "0"
                                                    }
                                                </td>
                                            </tr>
                                            <tr>    
                                                <td colSpan={6}
                                                >
                                                   0 - 18

                                                </td>
  
                                                <td
                                                    colSpan={2}
                                                >
                                                    { 
                                                     policyDetails?.request_metadata?.employee_details?.["0-18"] ?? "0"
                                                    }
                                                </td>
                                            </tr>
                                            <tr>    
                                                <td colSpan={6}
                                                >
                                                   19 - 35

                                                </td>
  
                                                <td
                                                    colSpan={2}
                                                >
                                                    { 
                                                     policyDetails?.request_metadata?.employee_details?.["19-35"] ?? "0"
                                                    }
                                                </td>
                                            </tr>
                                            <tr>    
                                                <td colSpan={6}
                                                >
                                                   36 - 45

                                                </td>
  
                                                <td
                                                    colSpan={2}
                                                >
                                                    { 
                                                     policyDetails?.request_metadata?.employee_details?.["36-45"] ?? "0"
                                                    }
                                                </td>
                                            </tr>
                                            <tr>    
                                                <td colSpan={6}
                                                >
                                                   46 - 55

                                                </td>
  
                                                <td
                                                    colSpan={2}
                                                >
                                                    { 
                                                     policyDetails?.request_metadata?.employee_details?.["46-55"] ?? "0"
                                                    }
                                                </td>
                                            </tr>
                                            <tr>    
                                                <td colSpan={6}
                                                >
                                                   56 - 65

                                                </td>
  
                                                <td
                                                    colSpan={2}
                                                >
                                                    { 
                                                     policyDetails?.request_metadata?.employee_details?.["56-65"] ?? "0"
                                                    }
                                                </td>
                                            </tr>
                                            <tr>    
                                                <td colSpan={6}
                                                >
                                                   66 and above

                                                </td>
  
                                                <td
                                                    colSpan={2}
                                                >
                                                    { 
                                                     policyDetails?.request_metadata?.employee_details?.["66 and above"] ?? "0"
                                                    }
                                                </td>
                                            </tr>
                                            </tbody>    
                                          
                                        
                                    </Table>                                                  
                                </Row>
                            </Accordion.Body>
                        </Accordion.Item>
                    }
                    {
                        <Accordion.Item eventKey="1">
                            <Accordion.Header>Coverage Details </Accordion.Header>
                            <Accordion.Body>
                                <Row style={{ padding: '20px' }}>
                                    {/* <h5>Coverage </h5> */}
                                    <Table bordered hover>
                                        <thead>
                                            <tr>
                                                <th colSpan={4}>Coverage Code</th>
                                                <td
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    {
                                                     policyDetails?.request_metadata
                                                        ?.coverage_details?.coverage ?? "N/A"
                                                    }


                                                </td>
                                                <th colSpan={2}>Coverage Name</th>
                                                <td
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    {
                                                        policyDetails?.request_metadata && 
                                                        getCoverageDescription(policyDetails?.request_metadata
                                                            ?.coverage_details?.coverage)
                                                    }
                                                </td>
                                            </tr>
                                          
                                        </thead>
                                    </Table>
                                    <Table bordered hover>
                                        <thead>
                                            <tr>
                                                <th colSpan={4}>Plan Name</th>
                                                <th colSpan={2}>Sum Insured </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>    
                                                <td colSpan={4}
                                                  
                                                >
                                                    { 

                                                      (policyDetails?.request_metadata?.recommendation_selection?.selected_plan_name ?? "N/A" ) 
                                                    }


                                                </td>
  
                                                <td
                                                    colSpan={2}
                                                >
                                                    
                                                    ₹ {  
                                                     policyDetails?.request_metadata?.recommendation_selection?.selected_sum_insured ?? "N/A" 
                                                    } Lakhs
                                                </td>
                                            </tr>
                                            </tbody>    
                                          
                                        
                                    </Table>                

                                    <Table bordered hover>
                                        <thead>
                                            <tr>
                                                <th colSpan={6}>Add On </th>
                                                <th colSpan={2}>Price </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>    
                                                <td colSpan={6}
                                                  
                                                >
                                                    { 
                                                     policyDetails?.request_metadata?.recommendation_selection?.selected_add_on.title ?? "N/A"
                                                    }


                                                </td>
  
                                                <td
                                                    colSpan={2}
                                                >
                                                    { 
                                                     policyDetails?.request_metadata?.recommendation_selection?.selected_add_on.complimentary ? 'Complimentary' : policyDetails?.request_metadata?.recommendation_selection?.add_on_price 
                                                    }
                                                </td>
                                            </tr>
                                            </tbody>    
                                          
                                        
                                    </Table>                                                  
                                </Row>
                            </Accordion.Body>
                        </Accordion.Item>
                    }
                     {
                        <Accordion.Item eventKey="2">
                            <Accordion.Header>Price Breakup </Accordion.Header>
                            <Accordion.Body>
                                <Row style={{ padding: '20px' }}>                   
                                    <Table bordered hover>
                                        <thead>
                                            <tr style={{ textAlign: 'left' }}>
                                                <th colSpan={2}>Summary</th>
                                                <th colSpan={4}  
                                                style={{
                                                        textAlign: 'right',
                                                    }}
                                                >Amount</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {policyDetails?.insurance_type !== "stock" && (
                                                <>
                                                    <tr>
                                                        <td colSpan={2}>
                                                        Subscription Fee
                                                        </td>
                                                        <td
                                                            colSpan={4}
                                                            style={{
                                                                textAlign: 'right',
                                                            }}
                                                        >
                                                            
                                                        { 
                                                            NumberFormat(policyDetails?.request_metadata?.recommendation_selection?.selected_premium   ?? 0) ?? '-'
                                                        }

                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan={2}>Taxes & Cess</td>
                                                        <td
                                                            colSpan={4}
                                                            style={{
                                                                textAlign: 'right',
                                                            }}
                                                        >
                                                        { 
                                                            NumberFormat(policyDetails?.request_metadata?.recommendation_selection?.tax  ?? 0) ?? '-'
                                                        }
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan={2}>
                                                            Promo Code 
                                                        </td>
                                                        <td
                                                            colSpan={4}
                                                            style={{
                                                                textAlign: 'right',
                                                            }}
                                                        >
                                                           { 
                                                                <i><b>{policyDetails?.request_metadata?.recommendation_selection?.promoCodeName || 'NA'  }</b></i>
                                                            }
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan={2}>
                                                        Your Total Saving
                                                        </td>
                                                        <td
                                                            colSpan={4}
                                                            style={{
                                                                textAlign: 'right',
                                                            }}
                                                        >
                                                            - { NumberFormat(policyDetails?.request_metadata?.recommendation_selection?.savings ?? 0) ?? '-' }
                                                        </td>
                                                    </tr>
                                                </>
                                            )}

                                          
                                        </tbody>
                                        <thead>
                                            <tr>
                                                <th colSpan={2}>Grand Total</th>
                                                <th
                                                    colSpan={4}
                                                    style={{ textAlign: 'right' }}
                                                >
                                                    {NumberFormat( policyDetails?.request_metadata?.recommendation_selection?.gross_amount  ?? 0) ??
                                                        '-'}
                                                </th>
                                            </tr>
                                        </thead>
                                    </Table>                                 
                                </Row>
                            </Accordion.Body>
                        </Accordion.Item>
                    }

                    {/* { policyDetails?.purchase_metadata &&  */}
                        <Accordion.Item eventKey="3">
                            <Accordion.Header>Payment Details  </Accordion.Header>
                            <Accordion.Body>
                            <Row style={{ padding: '20px' }}>                   
                                    <Table bordered hover>
                                        <tbody>
                                        <tr>
                                            <td colSpan={2}>Transaction ID</td>
                                            <td
                                                colSpan={4}
                                                style={{
                                                    textAlign: 'right',
                                                }}
                                            >
                                            { 
                                                policyDetails?.purchase_metadata?.transactionId || 'NA'
                                            }
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan={2}>Order ID</td>
                                            <td
                                                colSpan={4}
                                                style={{
                                                    textAlign: 'right',
                                                }}
                                            >
                                            { 
                                                policyDetails?.purchase_metadata?.orderId || 'NA'
                                            }
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan={2}>Receipt ID</td>
                                            <td
                                                colSpan={4}
                                                style={{
                                                    textAlign: 'right',
                                                }}
                                            >
                                            { 
                                                    policyDetails?.purchase_metadata?.receiptId  ?? 'NA'
                                            }
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan={2}>Payment Status</td>
                                            <td
                                                colSpan={4}
                                                style={{
                                                    textAlign: 'right',
                                                }}
                                            >
                                            { 
                                                policyDetails?.purchase_metadata?.paymentStatus || 'NA'
                                            }
                                            </td>
                                        </tr>
                                     
                                        <tr>
                                            <td colSpan={2}>Payment Mode</td>
                                            <td
                                                colSpan={4}
                                                style={{
                                                    textAlign: 'right',
                                                }}
                                            >
                                            { 
                                                policyDetails?.purchase_metadata?.mode  || 'NA'
                                            }
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan={2}>Card Type</td>
                                            <td
                                                colSpan={4}
                                                style={{
                                                    textAlign: 'right',
                                                }}
                                            >
                                            { 
                                                policyDetails?.purchase_metadata?.cardType  || 'NA'
                                            }
                                            </td>
                                        </tr>
                                     
                                        <tr>
                                            <td colSpan={2}>Description</td>
                                            <td
                                                colSpan={4}
                                                style={{
                                                    textAlign: 'right',
                                                }}
                                            >
                                            { 
                                                    policyDetails?.purchase_metadata?.description  ?? 'NA'
                                            }
                                            </td>
                                        </tr>
                                        </tbody>
                                    </Table>    

                                </Row>
                            </Accordion.Body>    
                        </Accordion.Item>    
                    {/* }     */}
                </Accordion>
            </Modal.Body>
        </Modal>
    );
};

export default GhStepDetails;
