import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import styles from './MerchantCover.module.scss';
import occupanyList from '../occupancyList.json';
import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import { CurrencyFormatter } from '../../utils/currencyFormatter';
import { NumberFormat } from '../../utils/currencyConverter';
import { notify } from '../../common/Toast';

interface Inputs {
    startDate: string;
    pincode: string;
    basementRisk: boolean;
    terrorismCovered: boolean;
    puccaOrKutcha: string;
    occupancyType: string;
    BuildingIncludingPlinthFoundation: string;
    Contents: string;
    FurnitureFixtureFittings: string;
    PlantMachinery: string;
    RawMaterial: string;
    StocksInProcess: string;
    FinishedStocks: string;
}

interface Quote {
    insurerCode: string;
    grossPremium: number;
    grossPremiumBreakup: {
        totalTaxOnPremium: number;
    };
}

interface Row {
    correlationId: string;
    quoteId: string;
    policyStartDate: string;
    policyEndDate: string;
    quotes: Quote[];
    quoteCreatedAt: string;
    status: string;
}

const MerchantCover = () => {
    const [inputs, setInputs] = useState<Inputs>({
        startDate: '',
        pincode: '',
        basementRisk: false,
        terrorismCovered: false,
        puccaOrKutcha: 'Pucca',
        occupancyType: 'Abrasive Manufacturing',
        BuildingIncludingPlinthFoundation: '0',
        Contents: '0',
        FurnitureFixtureFittings: '0',
        PlantMachinery: '0',
        RawMaterial: '0',
        StocksInProcess: '0',
        FinishedStocks: '0',
    });
    const [quoteData, setQuoteData] = useState({
        insurerCode: '',
        startDate: '',
        endDate: '',
        grossPremium: '',
        totalTaxonPremium: '',
        quoteExpiryDate: '',
        totalSumInsured: 0,
    });

    const [displayQuote, setDisplayQuote] = useState(false);
    const [allQuotes, setAllQuotes] = useState([]);
    const handleInputChange = (
        event: React.ChangeEvent<
            HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
        >,
    ) => {
        const { name, value, tagName } = event.target;

        if (
            tagName === 'INPUT' &&
            event.target.getAttribute('type') === 'checkbox'
        ) {
            setInputs((prevInputs) => ({
                ...prevInputs,
                [name]: (event.target as HTMLInputElement).checked,
            }));
        } else {
            setInputs((prevInputs) => ({
                ...prevInputs,
                [name]: value,
            }));
        }
    };

    const fetchQuotes = async () => {
        try {
            const authData = JSON.parse(
                localStorage.getItem('authCheck') ?? 'null',
            );
            const headers = {
                'Content-Type': 'application/json', // Assuming JSON data
                Authorization: `Bearer ${authData.adminAuth}`, // Example Authorization header
            };
            const response = await axios.get(
                `${process.env.REACT_APP_BASE_URL}/v1/merchant-cover/quotes?status=created`,
                { headers: headers },
            );
            setAllQuotes(response.data.data);
        } catch (error) {
            notify.error('Error while fetching');
        }
    };

    const handleSubmit = async () => {
        const body = {
            insurers: ['iciciLombard'],
            correlationId: uuidv4(),
            policyStartDate: inputs.startDate,
            typeOfPolicy: 'New Business',
            locationBasedRiskAndCoverPackages: [
                {
                    locationId: 1,
                    occupancyType: inputs.occupancyType,
                    riskLocation: {
                        pincode: inputs.pincode,
                    },
                    buildingRiskProfile: {
                        constructionDetails: {
                            puccaOrKutcha: inputs.puccaOrKutcha,
                            typeOfClass: 'Class A',
                        },
                        hasBasementRisk: inputs.basementRisk,
                    },
                    risks: [
                        {
                            riskName: 'Building Including Plinth & Foundation',
                            sumInsured: inputs.BuildingIncludingPlinthFoundation
                                ? CurrencyFormatter(
                                      inputs.BuildingIncludingPlinthFoundation,
                                  )
                                : '0',
                        },
                        {
                            riskName: 'Contents',
                            sumInsured: inputs.Contents
                                ? CurrencyFormatter(inputs.Contents)
                                : '0',
                        },
                        {
                            riskName: 'Furniture Fixture & Fittings',
                            sumInsured: inputs.FurnitureFixtureFittings
                                ? CurrencyFormatter(
                                      inputs.FurnitureFixtureFittings,
                                  )
                                : '0',
                        },
                        {
                            riskName: 'Plant & Machinery',
                            sumInsured: inputs.PlantMachinery
                                ? CurrencyFormatter(inputs.PlantMachinery)
                                : '0',
                        },
                        {
                            riskName: 'Raw Material',
                            sumInsured: inputs.RawMaterial
                                ? CurrencyFormatter(inputs.RawMaterial)
                                : '0',
                        },
                        {
                            riskName: 'Stocks in Process',
                            sumInsured: inputs.StocksInProcess
                                ? CurrencyFormatter(inputs.StocksInProcess)
                                : '0',
                        },
                        {
                            riskName: 'Finished Stocks',
                            sumInsured: inputs.FinishedStocks
                                ? CurrencyFormatter(inputs.FinishedStocks)
                                : '0',
                        },
                    ],
                    standardCovers: [
                        {
                            coverName: 'STFI Cover',
                            required: true,
                        },
                        {
                            coverName: 'RSMD Cover',
                            required: true,
                        },
                        {
                            coverName: 'Earthquake Cover',
                            required: true,
                        },
                        {
                            coverName: 'Terrorism Cover',
                            required: inputs.terrorismCovered,
                        },
                    ],
                },
            ],
        };
        const authData = JSON.parse(
            localStorage.getItem('authCheck') ?? 'null',
        );
        const headers = {
            'Content-Type': 'application/json', // Assuming JSON data
            Authorization: `Bearer ${authData.adminAuth}`, // Example Authorization header
        };

        if (inputs.puccaOrKutcha === 'Kutcha') {
            notify.error("Construction Details can't be Kutcha");
        } else if (inputs.basementRisk === true) {
            notify.error("Basement Risk can't be marked");
        } else if (inputs.startDate === '' || inputs.pincode === '') {
            notify.error('Fill up all the Fields');
        } else {
            try {
                const response = await axios.post(
                    `${process.env.REACT_APP_BASE_URL}/v1/merchant-cover/quotes`,
                    body,
                    { headers: headers },
                );
                const { data } = response;
                if (data.success) {
                    setDisplayQuote(true);
                    fetchQuotes();
                    notify.success('Quote Generated!');
                    const toalSumInsured =
                        0 +
                        CurrencyFormatter(
                            inputs.BuildingIncludingPlinthFoundation,
                        ) +
                        CurrencyFormatter(inputs.Contents) +
                        CurrencyFormatter(inputs.FinishedStocks) +
                        CurrencyFormatter(inputs.FurnitureFixtureFittings) +
                        CurrencyFormatter(inputs.PlantMachinery) +
                        CurrencyFormatter(inputs.RawMaterial) +
                        CurrencyFormatter(inputs.StocksInProcess);

                    setQuoteData({
                        ...quoteData,
                        insurerCode: data.data.quotes[0].insurerCode,
                        startDate: data.data.policyStartDate,
                        endDate: data.data.policyEndDate,
                        grossPremium: data.data.quotes[0].grossPremium,
                        totalTaxonPremium:
                            data.data.quotes[0].grossPremiumBreakup
                                .totalTaxOnPremium,
                        quoteExpiryDate: data.data.quotes[0].quoteExpiryAt,
                        totalSumInsured: toalSumInsured,
                    });
                }
            } catch (error: any) {
                notify.error(error.response.data.errors.Error.message);
            }
        }
    };

    useEffect(() => {
        fetchQuotes();
    }, []);
    return (
        <div className={styles.main}>
            <div className={styles.tabs}>
                <Tabs defaultActiveKey="home" id="fill-tab-example" fill>
                    <Tab
                        eventKey="home"
                        title="Generate Quote"
                        className={styles.generateQuote}
                    >
                        {displayQuote ? (
                            <>
                                <h1 className={styles.heading}>
                                    Generated Quote
                                </h1>
                                <div className={styles.outerBox}>
                                    <div className={styles.tab1}>
                                        <label className={styles.quoteDetails}>
                                            <p className={styles.about}>
                                                Policy Start Date
                                            </p>
                                            <p className={styles.detail}>
                                                {quoteData.startDate}
                                            </p>
                                        </label>
                                        <label className={styles.quoteDetails}>
                                            <p className={styles.about}>
                                                Policy End Date
                                            </p>
                                            <p className={styles.detail}>
                                                {quoteData.endDate}
                                            </p>
                                        </label>

                                        <label className={styles.quoteDetails}>
                                            <p className={styles.about}>
                                                Insurer Name
                                            </p>
                                            <p className={styles.detail}>
                                                {quoteData.insurerCode}
                                            </p>
                                        </label>

                                        <label className={styles.quoteDetails}>
                                            <p className={styles.about}>
                                                Gross Premium
                                            </p>
                                            <p className={styles.detail}>
                                                {NumberFormat(
                                                    Number(
                                                        quoteData.grossPremium,
                                                    ),
                                                )}
                                            </p>
                                        </label>

                                        <label className={styles.quoteDetails}>
                                            <p className={styles.about}>
                                                Total Tax on Premium
                                            </p>
                                            <p className={styles.detail}>
                                                {NumberFormat(
                                                    Number(
                                                        quoteData.totalTaxonPremium,
                                                    ),
                                                )}
                                            </p>
                                        </label>

                                        <label className={styles.quoteDetails}>
                                            <p className={styles.about}>
                                                Total Sum Insured
                                            </p>
                                            <p className={styles.detail}>
                                                {NumberFormat(
                                                    quoteData.totalSumInsured,
                                                )}
                                            </p>
                                        </label>

                                        <label className={styles.quoteDetails}>
                                            <p className={styles.about}>
                                                Quote Expiry Date
                                            </p>
                                            <p className={styles.detail}>
                                                {quoteData.quoteExpiryDate}
                                            </p>
                                        </label>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <>
                                <h1 className={styles.heading}>
                                    Merchant Cover Get Quote Form
                                </h1>
                                <div className={styles.outerBox}>
                                    <div className={styles.tab1}>
                                        <div className={styles.inputBox}>
                                            <label className={styles.required}>
                                                Start Date
                                            </label>
                                            <input
                                                type="date"
                                                name="startDate"
                                                value={inputs.startDate}
                                                onChange={handleInputChange}
                                                required
                                            />
                                        </div>
                                        <div className={styles.inputBox}>
                                            <label className={styles.required}>
                                                Pincode
                                            </label>
                                            <input
                                                type="text"
                                                name="pincode"
                                                value={inputs.pincode}
                                                onChange={handleInputChange}
                                                required
                                            />
                                        </div>
                                        <div className={styles.checkBox}>
                                            <label>Basement Risk</label>
                                            <input
                                                type="checkbox"
                                                name="basementRisk"
                                                checked={inputs.basementRisk}
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                        <div className={styles.checkBox}>
                                            <label>
                                                Terrorism Cover Required
                                            </label>
                                            <input
                                                type="checkbox"
                                                name="terrorismCovered"
                                                checked={
                                                    inputs.terrorismCovered
                                                }
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                        <div className={styles.inputBox}>
                                            <label>Construction Details</label>
                                            <select
                                                name="constructionDetails"
                                                value={inputs.puccaOrKutcha}
                                                onChange={handleInputChange}
                                            >
                                                <option value="Pucca">
                                                    Pucca
                                                </option>
                                                <option value="Kutcha">
                                                    Kutcha
                                                </option>
                                            </select>
                                        </div>
                                        <div className={styles.inputBox}>
                                            <label>Occupancy Type</label>
                                            <select
                                                name="occupancyType"
                                                value={inputs.occupancyType}
                                                onChange={handleInputChange}
                                            >
                                                {Object.keys(occupanyList).map(
                                                    (option, index) => (
                                                        <option
                                                            key={index}
                                                            value={option}
                                                        >
                                                            {option}
                                                        </option>
                                                    ),
                                                )}
                                            </select>
                                        </div>
                                        <div className={styles.inputBox}>
                                            <label>
                                                Building Including Plinth &
                                                Foundation - Sum Insured
                                            </label>
                                            <input
                                                type="text"
                                                name="BuildingIncludingPlinthFoundation"
                                                value={
                                                    inputs.BuildingIncludingPlinthFoundation
                                                }
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                        <div className={styles.inputBox}>
                                            <label>
                                                Contents - Sum Insured
                                            </label>
                                            <input
                                                type="text"
                                                name="Contents"
                                                value={inputs.Contents}
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                        <div className={styles.inputBox}>
                                            <label>
                                                Furniture Fixture & Fittings -
                                                Sum Insured
                                            </label>
                                            <input
                                                type="text"
                                                name="FurnitureFixtureFittings"
                                                value={
                                                    inputs.FurnitureFixtureFittings
                                                }
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                        <div className={styles.inputBox}>
                                            <label>
                                                Plant & Machinery - Sum Insured
                                            </label>
                                            <input
                                                type="text"
                                                name="PlantMachinery"
                                                value={inputs.PlantMachinery}
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                        <div className={styles.inputBox}>
                                            <label>
                                                Raw Material - Sum Insured
                                            </label>
                                            <input
                                                type="text"
                                                name="RawMaterial"
                                                value={inputs.RawMaterial}
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                        <div className={styles.inputBox}>
                                            <label>
                                                Stocks in Process - Sum Insured
                                            </label>
                                            <input
                                                type="text"
                                                name="StocksInProcess"
                                                value={inputs.StocksInProcess}
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                        <div className={styles.inputBox}>
                                            <label>
                                                Finished Stocks - Sum Insured
                                            </label>
                                            <input
                                                type="text"
                                                name="FinishedStocks"
                                                value={inputs.FinishedStocks}
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                    </div>
                                    <Button
                                        className={styles.button}
                                        variant="primary"
                                        onClick={handleSubmit}
                                    >
                                        Get Quote
                                    </Button>
                                </div>
                            </>
                        )}
                    </Tab>
                    <Tab eventKey="profile" title="Get All Quotes">
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>Quote ID</th>
                                    <th>Start Date</th>
                                    <th>End Date</th>
                                    <th>Insurer</th>
                                    <th>Gross Premium</th>
                                    <th>Tax on Premium</th>
                                    <th>Created At</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {allQuotes?.map((row: Row) => (
                                    <tr key={row.correlationId}>
                                        <td>{row.quoteId}</td>
                                        <td>{row.policyStartDate}</td>
                                        <td>{row.policyEndDate}</td>
                                        <td>{row.quotes[0].insurerCode}</td>
                                        <td>
                                            {NumberFormat(
                                                Number(
                                                    row.quotes[0].grossPremium,
                                                ),
                                            )}
                                        </td>
                                        <td>
                                            {NumberFormat(
                                                Number(
                                                    row.quotes[0]
                                                        .grossPremiumBreakup
                                                        .totalTaxOnPremium,
                                                ),
                                            )}
                                        </td>
                                        <td>{row.quoteCreatedAt}</td>
                                        <td>{row.status}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Tab>
                </Tabs>
            </div>
        </div>
    );
};

export default MerchantCover;
