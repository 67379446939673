import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Button, Col, Modal, Row, Table } from 'react-bootstrap';
import { toast } from 'react-toastify';
import styles from './PolicyStepDetails.module.scss';
import tickIcon from '../../assets/images/tickIcon.svg';
import crossIcon from '../../assets/images/crossIcon.svg';
import { dateFormat } from '../../utils/dateFormat';
import { NumberFormat } from '../../utils/currencyConverter';
import Accordion from 'react-bootstrap/Accordion'

interface ModalProps {
    show: boolean;
    onHide: () => void;
    policyDetails: any;
}

const PolicyStepDetails = ({ show, onHide, policyDetails }: ModalProps) => {
    const standardCover = policyDetails?.request_metadata?.locationBasedRiskAndCoverPackages?.[0]
        ?.standardCovers ?? [];

    let risks: any = [];

    policyDetails?.request_metadata?.locationBasedRiskAndCoverPackages?.forEach(
        (location: any) => {
            risks = risks.concat(location?.risks);
        },
    );

    const risksTotal =
        policyDetails?.insurance_type === "stock"
            ? risks
                ?.map((x: any) =>
                    [
                        'Raw Material',
                        'Stocks in Process',
                        'Finished Stocks',
                    ].includes(x?.riskName)
                        ? x?.sumInsured ?? 0
                        : 0,
                )
                ?.reduce((a: any, b: any) => a + b, 0) : risks
                    ?.map((x: any) =>
                        [
                            'Building Including Plinth & Foundation',
                            'Contents',
                            'Furniture Fixture & Fittings',
                            'Plant & Machinery',
                        ].includes(x?.riskName)
                            ? x?.sumInsured ?? 0
                            : 0,
                    )
                    ?.reduce((a: any, b: any) => a + b, 0);

    const handleModalClose = () => {
        onHide();
    };

    return (
        <Modal
            show={show}
            onHide={onHide}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title
                    id="contained-modal-title-vcenter"
                    className={styles.modalTitle}
                >
                    Step Details {' '}
                    {policyDetails?.purchasePolicyDetails?.cover?.riskName}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Accordion defaultActiveKey="0">
                    {
                        policyDetails?.stepNumber > 1 &&
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>Pre-sum Insured</Accordion.Header>
                            <Accordion.Body>
                                <Table striped bordered hover>
                                    <thead>
                                        <tr>
                                            <th colSpan={4}>Business Name</th>
                                            <th colSpan={4}>Owner / User Name</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td colSpan={4}>
                                                {
                                                    policyDetails?.request_metadata
                                                        ?.onBoardingData?.personalDetails?.company_name ?? "N/A"
                                                }
                                            </td>
                                            <td colSpan={4}>
                                                {
                                                    policyDetails?.first_name + " " + policyDetails?.last_name
                                                }
                                            </td>
                                        </tr>
                                        <tr>
                                            <th colSpan={2}>
                                                Contact Email
                                            </th>
                                            <th colSpan={2}>
                                                Contact Phone Number
                                            </th>
                                            <th colSpan={2}>
                                                Entity Type
                                            </th>
                                            <th colSpan={2}>
                                                Construction Type
                                            </th>
                                        </tr>
                                        <tr>
                                            <td colSpan={2}>
                                                <a href={`mailto:${policyDetails?.email}`}>{policyDetails?.email}</a>
                                            </td>
                                            <td colSpan={2}>
                                                <a href={`tel:${policyDetails?.phone_number}`}>{policyDetails?.phone_number}</a>
                                            </td>
                                            <td colSpan={2}>
                                                {
                                                    policyDetails?.request_metadata
                                                        ?.onBoardingData?.companyDetails?.company_type?.name
                                                }
                                            </td>
                                            <td colSpan={2}>
                                                {
                                                    policyDetails?.request_metadata?.locationBasedRiskAndCoverPackages?.[0]?.buildingRiskProfile?.constructionDetails?.puccaOrKutcha ?? "N/A"
                                                }
                                            </td>
                                        </tr>
                                        <tr>
                                            <th colSpan={4}>
                                                Turnover
                                            </th>
                                            <th colSpan={4}>
                                                Funding Status
                                            </th>

                                        </tr>
                                        <tr>
                                            <td colSpan={4}>
                                                {
                                                    policyDetails?.request_metadata
                                                        ?.onBoardingData?.companyDetails?.turnover?.name
                                                }
                                            </td>
                                            <td colSpan={4}>
                                                {
                                                    policyDetails?.request_metadata
                                                        ?.onBoardingData?.companyDetails?.is_companyfunded ? policyDetails?.request_metadata
                                                            ?.onBoardingData?.companyDetails?.fundingAmount : "Not Funded"
                                                }
                                            </td>
                                        </tr>
                                        <tr>
                                            <th colSpan={4}>
                                                Industry Type
                                            </th>
                                            <th colSpan={4}>
                                                Business Category
                                            </th>

                                        </tr>
                                        <tr>
                                            <td colSpan={4}>
                                                {
                                                    policyDetails?.request_metadata
                                                        ?.natureOfBusiness ?? "N/A"
                                                }
                                            </td>
                                            <td colSpan={4}>
                                                {
                                                    policyDetails?.request_metadata
                                                        ?.categoryOfBusiness ?? "N/A"
                                                }
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </Accordion.Body>
                        </Accordion.Item>
                    }
                    {
                        policyDetails?.stepNumber > 4 && <Accordion.Item eventKey="1">
                            <Accordion.Header>Sum Insured</Accordion.Header>
                            <Accordion.Body>
                                <Row style={{ padding: '20px' }}>
                                    <h5>Cover Details</h5>
                                    <Table bordered hover>
                                        <thead>
                                            <tr>
                                                <th colSpan={2}>RSMD</th>
                                                <td
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <img
                                                        className={styles.tickCrossIcon}
                                                        src={
                                                            standardCover?.find(
                                                                (x: any) =>
                                                                    x?.coverName ===
                                                                    'RSMD Cover',
                                                            )?.required === true
                                                                ? tickIcon
                                                                : crossIcon
                                                        }
                                                    />
                                                </td>
                                                <th colSpan={2}>STFI</th>
                                                <td
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <img
                                                        className={styles.tickCrossIcon}
                                                        src={
                                                            standardCover?.find(
                                                                (x: any) =>
                                                                    x?.coverName ===
                                                                    'STFI Cover',
                                                            )?.required === true
                                                                ? tickIcon
                                                                : crossIcon
                                                        }
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <th colSpan={2}>Earthquake</th>
                                                <td
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <img
                                                        className={styles.tickCrossIcon}
                                                        src={
                                                            standardCover?.find(
                                                                (x: any) =>
                                                                    x?.coverName ===
                                                                    'Earthquake Cover',
                                                            )?.required === true
                                                                ? tickIcon
                                                                : crossIcon
                                                        }
                                                    />
                                                </td>
                                                <th colSpan={2}>Terrorism</th>
                                                <td
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <img
                                                        className={styles.tickCrossIcon}
                                                        src={
                                                            standardCover?.find(
                                                                (x: any) =>
                                                                    x?.coverName ===
                                                                    'Terrorism Cover',
                                                            )?.required === true
                                                                ? tickIcon
                                                                : crossIcon
                                                        }
                                                    />
                                                </td>
                                            </tr>
                                        </thead>
                                    </Table>
                                    <h5>Assets Values</h5>
                                    <Table bordered hover>
                                        <thead>
                                            <tr style={{ textAlign: 'center' }}>
                                                <th colSpan={2}>Valuation Type</th>
                                                <th colSpan={4}>Value</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {policyDetails?.insurance_type !== "stock" && (
                                                <>
                                                    <tr>
                                                        <td colSpan={2}>
                                                            Building Including Plinth &
                                                            Foundation
                                                        </td>
                                                        <td
                                                            colSpan={4}
                                                            style={{
                                                                textAlign: 'right',
                                                            }}
                                                        >
                                                            {NumberFormat(
                                                                risks
                                                                    ?.map((risk: any) =>
                                                                        risk.riskName ===
                                                                            'Building Including Plinth & Foundation'
                                                                            ? risk.sumInsured ??
                                                                            0
                                                                            : 0,
                                                                    )
                                                                    ?.reduce(
                                                                        (
                                                                            a: any,
                                                                            b: any,
                                                                        ) => a + b,
                                                                        0,
                                                                    ) ?? 0,
                                                            ) ?? '-'}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan={2}>Contents</td>
                                                        <td
                                                            colSpan={4}
                                                            style={{
                                                                textAlign: 'right',
                                                            }}
                                                        >
                                                            {NumberFormat(
                                                                risks
                                                                    ?.map((risk: any) =>
                                                                        risk.riskName ===
                                                                            'Contents'
                                                                            ? risk.sumInsured ??
                                                                            0
                                                                            : 0,
                                                                    )
                                                                    ?.reduce(
                                                                        (
                                                                            a: any,
                                                                            b: any,
                                                                        ) => a + b,
                                                                        0,
                                                                    ) ?? 0,
                                                            ) ?? '-'}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan={2}>
                                                            Furniture Fixture & Fittings
                                                        </td>
                                                        <td
                                                            colSpan={4}
                                                            style={{
                                                                textAlign: 'right',
                                                            }}
                                                        >
                                                            {NumberFormat(
                                                                risks
                                                                    ?.map((risk: any) =>
                                                                        risk.riskName ===
                                                                            'Furniture Fixture & Fittings'
                                                                            ? risk.sumInsured ??
                                                                            0
                                                                            : 0,
                                                                    )
                                                                    ?.reduce(
                                                                        (
                                                                            a: any,
                                                                            b: any,
                                                                        ) => a + b,
                                                                        0,
                                                                    ) ?? 0,
                                                            ) ?? '-'}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan={2}>
                                                            Plant & Machinery
                                                        </td>
                                                        <td
                                                            colSpan={4}
                                                            style={{
                                                                textAlign: 'right',
                                                            }}
                                                        >
                                                            {NumberFormat(
                                                                risks
                                                                    ?.map((risk: any) =>
                                                                        risk.riskName ===
                                                                            'Plant & Machinery'
                                                                            ? risk.sumInsured ??
                                                                            0
                                                                            : 0,
                                                                    )
                                                                    ?.reduce(
                                                                        (
                                                                            a: any,
                                                                            b: any,
                                                                        ) => a + b,
                                                                        0,
                                                                    ) ?? 0,
                                                            ) ?? '-'}
                                                        </td>
                                                    </tr>
                                                </>
                                            )}

                                            {policyDetails?.insurance_type === "stock" && (
                                                <>
                                                    <tr>
                                                        <td colSpan={2}>
                                                            Raw Material
                                                        </td>
                                                        <td
                                                            colSpan={4}
                                                            style={{
                                                                textAlign: 'right',
                                                            }}
                                                        >
                                                            {NumberFormat(
                                                                risks
                                                                    ?.map((risk: any) =>
                                                                        risk.riskName ===
                                                                            'Raw Material'
                                                                            ? risk.sumInsured ??
                                                                            0
                                                                            : 0,
                                                                    )
                                                                    ?.reduce(
                                                                        (
                                                                            a: any,
                                                                            b: any,
                                                                        ) => a + b,
                                                                        0,
                                                                    ) ?? 0,
                                                            ) ?? '-'}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan={2}>
                                                            Stocks in Process
                                                        </td>
                                                        <td
                                                            colSpan={4}
                                                            style={{
                                                                textAlign: 'right',
                                                            }}
                                                        >
                                                            {NumberFormat(
                                                                risks
                                                                    ?.map((risk: any) =>
                                                                        risk.riskName ===
                                                                            'Stocks in Process'
                                                                            ? risk.sumInsured ??
                                                                            0
                                                                            : 0,
                                                                    )
                                                                    ?.reduce(
                                                                        (
                                                                            a: any,
                                                                            b: any,
                                                                        ) => a + b,
                                                                        0,
                                                                    ) ?? 0,
                                                            ) ?? '-'}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan={2}>
                                                            Finished Stocks
                                                        </td>
                                                        <td
                                                            colSpan={4}
                                                            style={{
                                                                textAlign: 'right',
                                                            }}
                                                        >
                                                            {NumberFormat(
                                                                risks
                                                                    ?.map((risk: any) =>
                                                                        risk.riskName ===
                                                                            'Finished Stocks'
                                                                            ? risk.sumInsured ??
                                                                            0
                                                                            : 0,
                                                                    )
                                                                    ?.reduce(
                                                                        (
                                                                            a: any,
                                                                            b: any,
                                                                        ) => a + b,
                                                                        0,
                                                                    ) ?? 0,
                                                            ) ?? '-'}
                                                        </td>
                                                    </tr>
                                                </>
                                            )}
                                        </tbody>
                                        <thead>
                                            <tr>
                                                <th colSpan={2}>Total</th>
                                                <th
                                                    colSpan={4}
                                                    style={{ textAlign: 'right' }}
                                                >
                                                    {NumberFormat(risksTotal ?? 0) ??
                                                        '-'}
                                                </th>
                                            </tr>
                                        </thead>
                                    </Table>
                                    <h5>Premium Details</h5>
                                    <Table striped bordered hover>
                                        <thead>
                                            <tr>
                                                <th colSpan={2}>Start Date</th>
                                                <td colSpan={2}>
                                                    {dateFormat(
                                                        policyDetails?.response_metadata
                                                            ?.policyStartDate,
                                                    )}
                                                </td>
                                                <th colSpan={2}>End Date</th>
                                                <td colSpan={2}>
                                                    {dateFormat(
                                                        policyDetails?.response_metadata
                                                            ?.policyEndDate,
                                                    )}
                                                </td>
                                            </tr>
                                        </thead>
                                    </Table>
                                    <Table bordered hover>
                                        <thead>
                                            <tr>
                                                <th
                                                    colSpan={
                                                        standardCover?.find(
                                                            (x: any) =>
                                                                x?.coverName ===
                                                                'Terrorism Cover',
                                                        )?.required === true
                                                            ? 10
                                                            : 8
                                                    }
                                                    style={{
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    Amounts
                                                </th>
                                            </tr>
                                        </thead>
                                        <thead>
                                            <tr>
                                                <th
                                                    colSpan={2}
                                                    style={{ textAlign: 'center' }}
                                                >
                                                    Base
                                                </th>
                                                {standardCover?.find(
                                                    (x: any) =>
                                                        x?.coverName ===
                                                        'Terrorism Cover',
                                                )?.required === true && (
                                                        <th
                                                            colSpan={2}
                                                            style={{ textAlign: 'center' }}
                                                        >
                                                            Terrorism
                                                        </th>
                                                    )}
                                                <th
                                                    colSpan={2}
                                                    style={{ textAlign: 'center' }}
                                                >
                                                    Gross
                                                </th>
                                                <th
                                                    colSpan={2}
                                                    style={{ textAlign: 'center' }}
                                                >
                                                    Taxes & Cess
                                                </th>
                                                <th
                                                    colSpan={2}
                                                    style={{ textAlign: 'center' }}
                                                >
                                                    Total Amount
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td
                                                    colSpan={2}
                                                    style={{ textAlign: 'center' }}
                                                >
                                                    {NumberFormat(
                                                        policyDetails?.response_metadata
                                                            ?.quotes?.[0]
                                                            ?.grossPremiumBreakup
                                                            ?.totalLocationPremium ?? 0,
                                                    )}
                                                </td>
                                                {standardCover?.find(
                                                    (x: any) =>
                                                        x?.coverName ===
                                                        'Terrorism Cover',
                                                )?.required === true && (
                                                        <td
                                                            colSpan={2}
                                                            style={{ textAlign: 'center' }}
                                                        >
                                                            {NumberFormat(
                                                                policyDetails?.response_metadata
                                                                    ?.quotes?.[0]
                                                                    ?.grossPremiumBreakup
                                                                    ?.totalTerrorismPremium ??
                                                                0,
                                                            )}
                                                        </td>
                                                    )}
                                                <td
                                                    colSpan={2}
                                                    style={{ textAlign: 'center' }}
                                                >
                                                    {NumberFormat(
                                                        (policyDetails?.response_metadata
                                                            ?.quotes?.[0]
                                                            ?.grossPremiumBreakup
                                                            ?.totalTerrorismPremium ??
                                                            0) +
                                                        (policyDetails?.response_metadata
                                                            ?.quotes?.[0]
                                                            ?.grossPremiumBreakup
                                                            ?.totalLocationPremium ??
                                                            0) ?? 0,
                                                    )}
                                                </td>
                                                <td
                                                    colSpan={2}
                                                    style={{ textAlign: 'center' }}
                                                >
                                                    {NumberFormat(
                                                        policyDetails?.response_metadata
                                                            ?.quotes?.[0]
                                                            ?.grossPremiumBreakup
                                                            ?.totalTaxOnPremium ?? 0,
                                                    )}
                                                </td>
                                                <td
                                                    colSpan={2}
                                                    style={{ textAlign: 'center' }}
                                                >
                                                    {NumberFormat(
                                                        policyDetails?.response_metadata
                                                            ?.quotes?.[0]
                                                            ?.grossPremium ?? 0,
                                                    )}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </Row>
                            </Accordion.Body>
                        </Accordion.Item>
                    }

                    {
                        policyDetails?.stepNumber > 10 && <Accordion.Item eventKey="2">
                            <Accordion.Header>Personal Details</Accordion.Header>
                            <Accordion.Body>
                                <Row style={{ padding: '20px' }}>
                                    <Table bordered hover>
                                        <thead>
                                            <tr>
                                                <th colSpan={2}>Insured Name</th>
                                                <td colSpan={2}>
                                                    {policyDetails?.request_metadata?.purchaseMetadata?.insuredName ??
                                                        'N/A'}
                                                </td>
                                                <th colSpan={2}>Designation</th>
                                                <td colSpan={2}>
                                                    {policyDetails?.request_metadata
                                                        ?.purchaseMetadata?.designation ??
                                                        'N/A'}
                                                </td>
                                            </tr>
                                        </thead>
                                        <thead>
                                            <tr>
                                                <th colSpan={2}>Date of Birth</th>
                                                <td colSpan={2}>
                                                    {dateFormat(policyDetails?.request_metadata?.purchaseMetadata?.dob) ??
                                                        'N/A'}
                                                </td>
                                                <th colSpan={2}>Gender</th>
                                                <td colSpan={2}>
                                                    {policyDetails?.request_metadata
                                                        ?.purchaseMetadata?.gender ??
                                                        'N/A'}
                                                </td>
                                            </tr>
                                        </thead>
                                        <thead>
                                            <tr>
                                                <th colSpan={2}>Address Line 1</th>
                                                <td colSpan={2}>
                                                    {policyDetails?.request_metadata?.purchaseMetadata?.address ??
                                                        'N/A'}
                                                </td>
                                                <th colSpan={2}>Address Line 2</th>
                                                <td colSpan={2}>
                                                    {policyDetails?.request_metadata
                                                        ?.purchaseMetadata?.address2 ??
                                                        'N/A'}
                                                </td>
                                            </tr>
                                        </thead>
                                        <thead>
                                            <tr>
                                                <th>City</th>
                                                <td>
                                                    {policyDetails?.request_metadata
                                                        ?.purchaseMetadata?.city ??
                                                        'N/A'}
                                                </td>
                                                <th>State</th>
                                                <td>
                                                    {policyDetails?.request_metadata
                                                        ?.purchaseMetadata?.state ??
                                                        'N/A'}
                                                </td>
                                                <th>Country</th>
                                                <td>
                                                    {policyDetails?.request_metadata
                                                        ?.purchaseMetadata?.country ??
                                                        'N/A'}
                                                </td>
                                                <th>Pincode</th>
                                                <td>
                                                    {policyDetails?.request_metadata
                                                        ?.purchaseMetadata?.pincode ??
                                                        'N/A'}
                                                </td>
                                            </tr>
                                        </thead>
                                    </Table>
                                    {policyDetails?.request_metadata
                                        ?.purchaseMetadata
                                        ?.differentCommunicationAddress && (
                                            <>
                                                <h5>Communication Address</h5>
                                                <Table bordered hover>
                                                    <thead>
                                                        <tr>
                                                            <th colSpan={2}>Name</th>
                                                            <td colSpan={6}>
                                                                {policyDetails?.request_metadata
                                                                    ?.purchaseMetadata
                                                                    ?.communicationName ??
                                                                    'N/A'}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th colSpan={2}>Email</th>
                                                            <td colSpan={2}>
                                                                {policyDetails?.request_metadata
                                                                    ?.purchaseMetadata
                                                                    ?.communicationEmail ??
                                                                    'N/A'}
                                                            </td>
                                                            <th colSpan={2}>Phone</th>
                                                            <td colSpan={2}>
                                                                {policyDetails?.request_metadata
                                                                    ?.purchaseMetadata
                                                                    ?.communicationPhoneNumber ??
                                                                    'N/A'}
                                                            </td>
                                                        </tr>
                                                    </thead>
                                                    <thead>
                                                        <tr>
                                                            <th colSpan={2}>
                                                                Address Line 1
                                                            </th>
                                                            <td colSpan={2}>
                                                                {policyDetails?.request_metadata
                                                                    ?.purchaseMetadata
                                                                    ?.communicationAddress ??
                                                                    'N/A'}
                                                            </td>
                                                            <th colSpan={2}>
                                                                Address Line 2
                                                            </th>
                                                            <td colSpan={2}>
                                                                {policyDetails?.request_metadata
                                                                    ?.purchaseMetadata
                                                                    ?.communicationAddress2 ??
                                                                    'N/A'}
                                                            </td>
                                                        </tr>
                                                    </thead>
                                                    <thead>
                                                        <tr>
                                                            <th>City</th>
                                                            <td>
                                                                {policyDetails?.request_metadata
                                                                    ?.purchaseMetadata
                                                                    ?.communicationCity ??
                                                                    'N/A'}
                                                            </td>
                                                            <th>State</th>
                                                            <td>
                                                                {policyDetails?.request_metadata
                                                                    ?.purchaseMetadata
                                                                    ?.communicationState ??
                                                                    'N/A'}
                                                            </td>
                                                            <th>Country</th>
                                                            <td>
                                                                {policyDetails?.request_metadata
                                                                    ?.purchaseMetadata
                                                                    ?.communicationCountry ??
                                                                    'N/A'}
                                                            </td>
                                                            <th>Pincode</th>
                                                            <td>
                                                                {policyDetails?.request_metadata
                                                                    ?.purchaseMetadata
                                                                    ?.communicationPincode ??
                                                                    'N/A'}
                                                            </td>
                                                        </tr>
                                                    </thead>
                                                </Table>
                                            </>
                                        )}
                                </Row>
                            </Accordion.Body>
                        </Accordion.Item>
                    }
                    {
                        policyDetails?.stepNumber > 11 && <Accordion.Item eventKey="3">
                            <Accordion.Header>Insured Location</Accordion.Header>
                            <Accordion.Body>
                                <Row style={{ padding: '20px' }}>
                                    {policyDetails?.request_metadata?.locationBasedRiskAndCoverPackages?.map(
                                        (location: any, index: number) => (
                                            <Table bordered hover key={index}>
                                                <thead>
                                                    <tr>
                                                        <th colSpan={2}>
                                                            Location Name
                                                        </th>
                                                        <td colSpan={4}>
                                                            {location?.riskLocation
                                                                ?.locationName ?? 'N/A'}
                                                        </td>
                                                        <th colSpan={1}>
                                                            Location Number
                                                        </th>
                                                        <td colSpan={1}>{index + 1}</td>
                                                    </tr>
                                                </thead>
                                                <thead>
                                                    <tr>
                                                        <th colSpan={2}>
                                                            Address Line 1
                                                        </th>
                                                        <td colSpan={2}>
                                                            {location?.riskLocation
                                                                ?.location_address ??
                                                                'N/A'}
                                                        </td>
                                                        <th colSpan={2}>
                                                            Address Line 2
                                                        </th>
                                                        <td colSpan={2}>
                                                            {location?.riskLocation
                                                                ?.location_address2 ??
                                                                'N/A'}
                                                        </td>
                                                    </tr>
                                                </thead>
                                                <thead>
                                                    <tr>
                                                        <th>City</th>
                                                        <td>
                                                            {location?.riskLocation
                                                                ?.location_city ??
                                                                'N/A'}
                                                        </td>
                                                        <th>State</th>
                                                        <td>
                                                            {location?.riskLocation
                                                                ?.location_state ??
                                                                'N/A'}
                                                        </td>
                                                        <th>Country</th>
                                                        <td>
                                                            {location?.riskLocation
                                                                ?.location_country ??
                                                                'N/A'}
                                                        </td>
                                                        <th>Pincode</th>
                                                        <td>
                                                            {location?.riskLocation
                                                                ?.pincode ??
                                                                'N/A'}
                                                        </td>
                                                    </tr>
                                                </thead>
                                                <thead>
                                                    <tr>
                                                        <th colSpan={7}>
                                                            Different communication
                                                            Address
                                                        </th>

                                                        <td
                                                            colSpan={2}
                                                            style={{
                                                                display: 'flex',
                                                                justifyContent:
                                                                    'center',
                                                                alignItems: 'center',
                                                            }}
                                                        >
                                                            <img
                                                                className={
                                                                    styles.tickCrossIcon
                                                                }
                                                                src={
                                                                    location
                                                                        ?.riskLocation
                                                                        ?.differentLocationCommunicationAddress ===
                                                                        true
                                                                        ? tickIcon
                                                                        : crossIcon
                                                                }
                                                                style={{
                                                                    height: '25px',
                                                                    width: '25px',
                                                                }}
                                                            />
                                                        </td>
                                                    </tr>
                                                </thead>
                                                {location?.riskLocation
                                                    ?.differentLocationCommunicationAddress ===
                                                    true && (
                                                        <>
                                                            <thead>
                                                                <tr>
                                                                    <th
                                                                        colSpan={8}
                                                                        style={{
                                                                            textAlign:
                                                                                'center',
                                                                        }}
                                                                    >
                                                                        Communication
                                                                        Address
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <thead>
                                                                <tr>
                                                                    <th colSpan={2}>
                                                                        Business Name
                                                                    </th>
                                                                    <td colSpan={2}>
                                                                        {location
                                                                            ?.riskLocation
                                                                            ?.communicationLocationAddressFinancierBusinessName ??
                                                                            'N/A'}
                                                                    </td>
                                                                    <th colSpan={2}>
                                                                        Agreement
                                                                    </th>
                                                                    <td colSpan={2}>
                                                                        {location
                                                                            ?.riskLocation
                                                                            ?.communicationLocationAddressAgreement ??
                                                                            'N/A'}
                                                                    </td>
                                                                </tr>
                                                            </thead>
                                                            {location?.riskLocation
                                                                ?.communicationLocationAddressAgreement && (
                                                                    <thead>
                                                                        <tr>
                                                                            <th colSpan={2}>
                                                                                Start Date
                                                                            </th>
                                                                            <td colSpan={2}>
                                                                                {dateFormat(
                                                                                    location
                                                                                        ?.riskLocation
                                                                                        ?.communicationLocationAddressStartDate,
                                                                                ) ?? 'N/A'}
                                                                            </td>
                                                                            <th colSpan={2}>
                                                                                End Date
                                                                            </th>
                                                                            <td colSpan={2}>
                                                                                {dateFormat(
                                                                                    location
                                                                                        ?.riskLocation
                                                                                        ?.communicationLocationAddressEndDate,
                                                                                ) ?? 'N/A'}
                                                                            </td>
                                                                        </tr>
                                                                    </thead>
                                                                )}
                                                            <thead>
                                                                <tr>
                                                                    <th colSpan={2}>
                                                                        Financier Name
                                                                    </th>
                                                                    <td colSpan={2}>
                                                                        {location
                                                                            ?.riskLocation
                                                                            ?.communicationLocationAddressFinancierName ??
                                                                            'N/A'}
                                                                    </td>
                                                                    <th colSpan={2}>
                                                                        Role
                                                                    </th>
                                                                    <td colSpan={2}>
                                                                        {location
                                                                            ?.riskLocation
                                                                            ?.communicationLocationAddressFinancierDesignation ??
                                                                            'N/A'}
                                                                    </td>
                                                                </tr>
                                                            </thead>
                                                            <thead>
                                                                <tr>
                                                                    <th colSpan={2}>
                                                                        Address Line 1
                                                                    </th>
                                                                    <td colSpan={2}>
                                                                        {location
                                                                            ?.riskLocation
                                                                            ?.communicationLocationAddressFinancierAddressLine1 ??
                                                                            'N/A'}
                                                                    </td>
                                                                    <th colSpan={2}>
                                                                        Address Line 2
                                                                    </th>
                                                                    <td colSpan={2}>
                                                                        {location
                                                                            ?.riskLocation
                                                                            ?.communicationLocationAddressFinancierAddressLine2 ??
                                                                            'N/A'}
                                                                    </td>
                                                                </tr>
                                                            </thead>
                                                            <thead>
                                                                <tr>
                                                                    <th>City</th>
                                                                    <td>
                                                                        {location
                                                                            ?.riskLocation
                                                                            ?.communicationLocationAddressFinancierCity ??
                                                                            'N/A'}
                                                                    </td>
                                                                    <th>State</th>
                                                                    <td>
                                                                        {location
                                                                            ?.riskLocation
                                                                            ?.communicationLocationAddressFinancierState ??
                                                                            'N/A'}
                                                                    </td>
                                                                    <th>Country</th>
                                                                    <td>
                                                                        {location
                                                                            ?.riskLocation
                                                                            ?.communicationLocationAddressFinancierCountry ??
                                                                            'N/A'}
                                                                    </td>
                                                                    <th>Pincode</th>
                                                                    <td>
                                                                        {location
                                                                            ?.riskLocation
                                                                            ?.communicationLocationAddressFinancierPincode ??
                                                                            'N/A'}
                                                                    </td>
                                                                </tr>
                                                            </thead>
                                                        </>
                                                    )}
                                                <thead>
                                                    <tr>
                                                        <th
                                                            colSpan={8}
                                                            style={{
                                                                textAlign: 'center',
                                                            }}
                                                        >
                                                            Sum Insured
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <thead>
                                                    <tr style={{ textAlign: 'center' }}>
                                                        <th colSpan={6}>
                                                            Valuation Type
                                                        </th>
                                                        <th colSpan={2}>Value</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {!policyDetails?.insurance_type.includes(
                                                        'stock',
                                                    ) && (
                                                            <>
                                                                <tr>
                                                                    <td colSpan={6}>
                                                                        Building Including
                                                                        Plinth & Foundation
                                                                    </td>
                                                                    <td
                                                                        colSpan={2}
                                                                        style={{
                                                                            textAlign:
                                                                                'right',
                                                                        }}
                                                                    >
                                                                        {NumberFormat(
                                                                            location?.risks?.find(
                                                                                (
                                                                                    risk: any,
                                                                                ) =>
                                                                                    risk.riskName ===
                                                                                    'Building Including Plinth & Foundation',
                                                                            )?.sumInsured ??
                                                                            0,
                                                                        ) ?? '-'}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td colSpan={6}>
                                                                        Contents
                                                                    </td>
                                                                    <td
                                                                        colSpan={2}
                                                                        style={{
                                                                            textAlign:
                                                                                'right',
                                                                        }}
                                                                    >
                                                                        {NumberFormat(
                                                                            location?.risks?.find(
                                                                                (
                                                                                    risk: any,
                                                                                ) =>
                                                                                    risk.riskName ===
                                                                                    'Contents',
                                                                            )?.sumInsured ??
                                                                            0,
                                                                        ) ?? '-'}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td colSpan={6}>
                                                                        Furniture Fixture &
                                                                        Fittings
                                                                    </td>
                                                                    <td
                                                                        colSpan={2}
                                                                        style={{
                                                                            textAlign:
                                                                                'right',
                                                                        }}
                                                                    >
                                                                        {NumberFormat(
                                                                            location?.risks?.find(
                                                                                (
                                                                                    risk: any,
                                                                                ) =>
                                                                                    risk.riskName ===
                                                                                    'Furniture Fixture & Fittings',
                                                                            )?.sumInsured ??
                                                                            0,
                                                                        ) ?? '-'}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td colSpan={6}>
                                                                        Plant & Machinery
                                                                    </td>
                                                                    <td
                                                                        colSpan={2}
                                                                        style={{
                                                                            textAlign:
                                                                                'right',
                                                                        }}
                                                                    >
                                                                        {NumberFormat(
                                                                            location?.risks?.find(
                                                                                (
                                                                                    risk: any,
                                                                                ) =>
                                                                                    risk.riskName ===
                                                                                    'Plant & Machinery',
                                                                            )?.sumInsured ??
                                                                            0,
                                                                        ) ?? '-'}
                                                                    </td>
                                                                </tr>
                                                            </>
                                                        )}

                                                    {(policyDetails?.insurance_type === "stock"
                                                    ) && (
                                                            <>
                                                                <tr>
                                                                    <td colSpan={6}>
                                                                        Raw Material
                                                                    </td>
                                                                    <td
                                                                        colSpan={2}
                                                                        style={{
                                                                            textAlign:
                                                                                'right',
                                                                        }}
                                                                    >
                                                                        {NumberFormat(
                                                                            location?.risks?.find(
                                                                                (
                                                                                    risk: any,
                                                                                ) =>
                                                                                    risk.riskName ===
                                                                                    'Raw Material',
                                                                            )?.sumInsured ??
                                                                            0,
                                                                        ) ?? '-'}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td colSpan={6}>
                                                                        Stocks in Process
                                                                    </td>
                                                                    <td
                                                                        colSpan={2}
                                                                        style={{
                                                                            textAlign:
                                                                                'right',
                                                                        }}
                                                                    >
                                                                        {NumberFormat(
                                                                            location?.risks?.find(
                                                                                (
                                                                                    risk: any,
                                                                                ) =>
                                                                                    risk.riskName ===
                                                                                    'Stocks in Process',
                                                                            )?.sumInsured ??
                                                                            0,
                                                                        ) ?? '-'}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td colSpan={6}>
                                                                        Finished Stocks
                                                                    </td>
                                                                    <td
                                                                        colSpan={2}
                                                                        style={{
                                                                            textAlign:
                                                                                'right',
                                                                        }}
                                                                    >
                                                                        {NumberFormat(
                                                                            location?.risks?.find(
                                                                                (
                                                                                    risk: any,
                                                                                ) =>
                                                                                    risk.riskName ===
                                                                                    'Finished Stocks',
                                                                            )?.sumInsured ??
                                                                            0,
                                                                        ) ?? '-'}
                                                                    </td>
                                                                </tr>
                                                            </>
                                                        )}
                                                </tbody>
                                                <thead>
                                                    <tr>
                                                        <th colSpan={6}>Total</th>
                                                        <th
                                                            colSpan={2}
                                                            style={{
                                                                textAlign: 'right',
                                                            }}
                                                        >
                                                            {NumberFormat(
                                                                location?.risks
                                                                    ?.map((risk: any) =>
                                                                        (policyDetails?.insurance_type === "stock"
                                                                            ? [
                                                                                'Raw Material',
                                                                                'Stocks in Process',
                                                                                'Finished Stocks',
                                                                            ]
                                                                            : [
                                                                                'Building Including Plinth & Foundation',
                                                                                'Contents',
                                                                                'Furniture Fixture & Fittings',
                                                                                'Plant & Machinery',
                                                                            ]
                                                                        ).includes(
                                                                            risk?.riskName,
                                                                        )
                                                                            ? risk?.sumInsured ??
                                                                            0
                                                                            : 0,
                                                                    )
                                                                    ?.reduce(
                                                                        (
                                                                            a: any,
                                                                            b: any,
                                                                        ) => a + b,
                                                                        0,
                                                                    ) ?? 0,
                                                            ) ?? '-'}
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <thead>
                                                    <tr>
                                                        <th
                                                            colSpan={8}
                                                            style={{
                                                                textAlign: 'center',
                                                            }}
                                                        >
                                                            Premium Breakup
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {policyDetails?.response_metadata?.quotes?.[0]?.premiumBreakupByLocations?.find(
                                                        (premiumBreakup: any) =>
                                                            premiumBreakup?.locationId ===
                                                            location?.locationId,
                                                    ) ? (
                                                        <>
                                                            <tr>
                                                                <td colSpan={6}>
                                                                    Location Premium
                                                                </td>
                                                                <td
                                                                    colSpan={2}
                                                                    style={{
                                                                        textAlign:
                                                                            'right',
                                                                    }}
                                                                >
                                                                    {NumberFormat(
                                                                        policyDetails?.response_metadata?.quotes?.[0]?.premiumBreakupByLocations?.find(
                                                                            (
                                                                                premiumBreakup: any,
                                                                            ) =>
                                                                                premiumBreakup?.locationId ===
                                                                                location?.locationId,
                                                                        )
                                                                            ?.locationPremium ??
                                                                        0,
                                                                    )}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td colSpan={6}>
                                                                    Terrorism Premium
                                                                </td>
                                                                <td
                                                                    colSpan={2}
                                                                    style={{
                                                                        textAlign:
                                                                            'right',
                                                                    }}
                                                                >
                                                                    {NumberFormat(
                                                                        policyDetails?.response_metadata?.quotes?.[0]?.premiumBreakupByLocations?.find(
                                                                            (
                                                                                premiumBreakup: any,
                                                                            ) =>
                                                                                premiumBreakup?.locationId ===
                                                                                location?.locationId,
                                                                        )
                                                                            ?.terrorismPremium ??
                                                                        0,
                                                                    )}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td colSpan={6}>
                                                                    Tax on Premium
                                                                </td>
                                                                <td
                                                                    colSpan={2}
                                                                    style={{
                                                                        textAlign:
                                                                            'right',
                                                                    }}
                                                                >
                                                                    {NumberFormat(
                                                                        policyDetails?.response_metadata?.quotes?.[0]?.premiumBreakupByLocations?.find(
                                                                            (
                                                                                premiumBreakup: any,
                                                                            ) =>
                                                                                premiumBreakup?.locationId ===
                                                                                location?.locationId,
                                                                        )
                                                                            ?.taxOnPremium ??
                                                                        0,
                                                                    )}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <th colSpan={6}>
                                                                    Total Premium
                                                                </th>
                                                                <th
                                                                    colSpan={2}
                                                                    style={{
                                                                        textAlign:
                                                                            'right',
                                                                    }}
                                                                >
                                                                    {NumberFormat(
                                                                        policyDetails?.response_metadata?.quotes?.[0]?.premiumBreakupByLocations?.find(
                                                                            (
                                                                                premiumBreakup: any,
                                                                            ) =>
                                                                                premiumBreakup?.locationId ===
                                                                                location?.locationId,
                                                                        )
                                                                            ?.totalPremium ??
                                                                        0,
                                                                    )}
                                                                </th>
                                                            </tr>
                                                        </>
                                                    ) : (
                                                        <></>
                                                    )}
                                                </tbody>
                                            </Table>
                                        ),
                                    )}
                                </Row>
                            </Accordion.Body>
                        </Accordion.Item>
                    }
                </Accordion>
            </Modal.Body>
        </Modal>
    );
};

export default PolicyStepDetails;
