import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Button, Col, Modal, Row, Table } from 'react-bootstrap';
import { toast } from 'react-toastify';
import styles from './style.module.scss';
import tickIcon from '../../assets/images/tickIcon.svg';
import crossIcon from '../../assets/images/crossIcon.svg';
import { dateFormat } from '../../utils/dateFormat';
import { NumberFormat } from '../../utils/currencyConverter';

interface ModalProps {
    show: boolean;
    onHide: () => void;
    policyId: number | null;
}

const PolicyDetailsModal = ({ show, onHide, policyId }: ModalProps) => {
    const [policyDetails, setPolicyDetails] = useState<any>({});
    const [loading, setLoading] = useState(false);

    const fetchPolicyDetails = async () => {
        try {
            setLoading(true);

            const authData = JSON.parse(
                localStorage.getItem('authCheck') ?? 'null',
            );
            const headers = {
                Authorization: `Bearer ${authData.adminAuth}`,
            };

            const response = await axios.get(
                `${process.env.REACT_APP_BASE_URL}/v1/admins/users/policies/${policyId}`,
                { headers },
            );

            if (response.data.status === 'success') {
                setPolicyDetails(response.data.data);
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            toast.error(`${error}`);
        } finally {
            setLoading(false);
        }
    };

    const standardCover =
        policyDetails?.purchasePolicyDetails?.purchasedCover?.merchantQuote
            ?.requestMetadata?.locationBasedRiskAndCoverPackages?.[0]
            ?.standardCovers ?? [];

    let risks: any = [];

    policyDetails?.purchasePolicyDetails?.purchasedCover?.merchantQuote?.requestMetadata?.locationBasedRiskAndCoverPackages?.forEach(
        (location: any) => {
            risks = risks.concat(location.risks);
        },
    );

    const risksTotal =
        !policyDetails?.purchasePolicyDetails?.cover?.riskName.includes('Stock')
            ? risks
                ?.map((x: any) =>
                    [
                        'Building Including Plinth & Foundation',
                        'Contents',
                        'Furniture Fixture & Fittings',
                        'Plant & Machinery',
                    ].includes(x?.riskName)
                        ? x?.sumInsured ?? 0
                        : 0,
                )
                ?.reduce((a: any, b: any) => a + b, 0)
            : risks
                ?.map((x: any) =>
                    [
                        'Raw Material',
                        'Stocks in Process',
                        'Finished Stocks',
                    ].includes(x?.riskName)
                        ? x?.sumInsured ?? 0
                        : 0,
                )
                ?.reduce((a: any, b: any) => a + b, 0);

    const handleModalClose = () => {
        setPolicyDetails({});
        onHide();
    };

    const HandlePdfFile = ({ url, docType }: { url: string, docType: string }): JSX.Element => {
        const handleDownloadClick = () => {
            const link = document.createElement("a");
            link.href = url;
            link.download = `KYC-${policyId}-${docType}.pdf`; // specify the filename
            link.target = "_blank";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
        return <div style={{ display: "flex", flexDirection: "column", "gap": "10px" }}>
            <object data={url} type="application/pdf" width="100%" height="100%">
                <p>Alternative text - include a link <a href={url}>to the PDF!</a></p>
            </object>
            <Button onClick={handleDownloadClick}>Download</Button>
        </div>
    };

    useEffect(() => {
        fetchPolicyDetails();
    }, []);

    return (
        <Modal
            show={show}
            onHide={onHide}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title
                    id="contained-modal-title-vcenter"
                    className={styles.modalTitle}
                >
                    Policy Details -{' '}
                    {policyDetails?.purchasePolicyDetails?.cover?.riskName}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {loading ? (
                    <div>Loading...</div>
                ) : (
                    <div>
                        <Row style={{ padding: '20px' }}>
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th colSpan={3}>Business Name</th>
                                        <th colSpan={3}>Owner's Name</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td colSpan={3}>
                                            {
                                                policyDetails?.purchasePolicyDetails?.purchasedCover?.merchantQuote
                                                    ?.requestMetadata?.onBoardingData?.personalDetails?.company_name ?? "N/A"
                                            }
                                        </td>
                                        <td colSpan={3}>{
                                            policyDetails
                                                ?.purchasePolicyDetails
                                                ?.purchasedCover
                                                ?.policyHolder ?? "N/A"
                                        }</td>
                                    </tr>
                                    <tr>
                                        <th colSpan={2}>Pincode</th>

                                        <th colSpan={2}>DOB</th>

                                        <th colSpan={2}>Gender</th>
                                    </tr>
                                    <tr>
                                        <td colSpan={2}>
                                            {policyDetails
                                                ?.purchasePolicyDetails
                                                ?.purchasedCover?.merchantQuote
                                                ?.requestMetadata
                                                ?.locationBasedRiskAndCoverPackages?.[0]
                                                ?.riskLocation?.pincode ??
                                                'N/A'}
                                        </td>
                                        <td colSpan={2}>
                                            {dateFormat(
                                                policyDetails
                                                    ?.purchasePolicyDetails
                                                    ?.purchasedCover
                                                    ?.merchantQuote
                                                    ?.purchaseMetadata?.dob,
                                            ) ?? 'N/A'}
                                        </td>

                                        <td colSpan={2}>
                                            {policyDetails
                                                ?.purchasePolicyDetails
                                                ?.purchasedCover?.merchantQuote
                                                ?.purchaseMetadata?.gender ??
                                                'N/A'}
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Row>
                        <Row style={{ padding: '20px' }}>
                            <h5>Billing Address</h5>
                            <Table bordered hover>
                                <thead>
                                    <tr>
                                        <th colSpan={2}>Address Line 1</th>
                                        <td colSpan={2}>
                                            {policyDetails
                                                ?.purchasePolicyDetails
                                                ?.purchasedCover?.merchantQuote
                                                ?.purchaseMetadata?.address ??
                                                'N/A'}
                                        </td>
                                        <th colSpan={2}>Address Line 2</th>
                                        <td colSpan={2}>
                                            {policyDetails
                                                ?.purchasePolicyDetails
                                                ?.purchasedCover?.merchantQuote
                                                ?.purchaseMetadata?.address2 ??
                                                'N/A'}
                                        </td>
                                    </tr>
                                </thead>
                                <thead>
                                    <tr>
                                        <th>City</th>
                                        <td>
                                            {policyDetails
                                                ?.purchasePolicyDetails
                                                ?.purchasedCover?.merchantQuote
                                                ?.purchaseMetadata?.city ??
                                                'N/A'}
                                        </td>
                                        <th>State</th>
                                        <td>
                                            {policyDetails
                                                ?.purchasePolicyDetails
                                                ?.purchasedCover?.merchantQuote
                                                ?.purchaseMetadata?.state ??
                                                'N/A'}
                                        </td>
                                        <th>Country</th>
                                        <td>
                                            {policyDetails
                                                ?.purchasePolicyDetails
                                                ?.purchasedCover?.merchantQuote
                                                ?.purchaseMetadata?.country ??
                                                'N/A'}
                                        </td>
                                        <th>Pincode</th>
                                        <td>
                                            {policyDetails
                                                ?.purchasePolicyDetails
                                                ?.purchasedCover?.merchantQuote
                                                ?.purchaseMetadata?.pincode ??
                                                'N/A'}
                                        </td>
                                    </tr>
                                </thead>
                            </Table>
                            {policyDetails?.purchasePolicyDetails
                                ?.purchasedCover?.merchantQuote
                                ?.purchaseMetadata
                                ?.differentCommunicationAddress && (
                                    <>
                                        <h5>Communication Address</h5>
                                        <Table bordered hover>
                                            <thead>
                                                <tr>
                                                    <th colSpan={2}>Name</th>
                                                    <td colSpan={6}>
                                                        {policyDetails
                                                            ?.purchasePolicyDetails
                                                            ?.purchasedCover
                                                            ?.merchantQuote
                                                            ?.purchaseMetadata
                                                            ?.communicationName ??
                                                            'N/A'}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th colSpan={2}>Email</th>
                                                    <td colSpan={2}>
                                                        {policyDetails
                                                            ?.purchasePolicyDetails
                                                            ?.purchasedCover
                                                            ?.merchantQuote
                                                            ?.purchaseMetadata
                                                            ?.communicationEmail ??
                                                            'N/A'}
                                                    </td>
                                                    <th colSpan={2}>Phone</th>
                                                    <td colSpan={2}>
                                                        {policyDetails
                                                            ?.purchasePolicyDetails
                                                            ?.purchasedCover
                                                            ?.merchantQuote
                                                            ?.purchaseMetadata
                                                            ?.communicationPhoneNumber ??
                                                            'N/A'}
                                                    </td>
                                                </tr>
                                            </thead>
                                            <thead>
                                                <tr>
                                                    <th colSpan={2}>
                                                        Address Line 1
                                                    </th>
                                                    <td colSpan={2}>
                                                        {policyDetails
                                                            ?.purchasePolicyDetails
                                                            ?.purchasedCover
                                                            ?.merchantQuote
                                                            ?.purchaseMetadata
                                                            ?.communicationAddress ??
                                                            'N/A'}
                                                    </td>
                                                    <th colSpan={2}>
                                                        Address Line 2
                                                    </th>
                                                    <td colSpan={2}>
                                                        {policyDetails
                                                            ?.purchasePolicyDetails
                                                            ?.purchasedCover
                                                            ?.merchantQuote
                                                            ?.purchaseMetadata
                                                            ?.communicationAddress2 ??
                                                            'N/A'}
                                                    </td>
                                                </tr>
                                            </thead>
                                            <thead>
                                                <tr>
                                                    <th>City</th>
                                                    <td>
                                                        {policyDetails
                                                            ?.purchasePolicyDetails
                                                            ?.purchasedCover
                                                            ?.merchantQuote
                                                            ?.purchaseMetadata
                                                            ?.communicationCity ??
                                                            'N/A'}
                                                    </td>
                                                    <th>State</th>
                                                    <td>
                                                        {policyDetails
                                                            ?.purchasePolicyDetails
                                                            ?.purchasedCover
                                                            ?.merchantQuote
                                                            ?.purchaseMetadata
                                                            ?.communicationState ??
                                                            'N/A'}
                                                    </td>
                                                    <th>Country</th>
                                                    <td>
                                                        {policyDetails
                                                            ?.purchasePolicyDetails
                                                            ?.purchasedCover
                                                            ?.merchantQuote
                                                            ?.purchaseMetadata
                                                            ?.communicationCountry ??
                                                            'N/A'}
                                                    </td>
                                                    <th>Pincode</th>
                                                    <td>
                                                        {policyDetails
                                                            ?.purchasePolicyDetails
                                                            ?.purchasedCover
                                                            ?.merchantQuote
                                                            ?.purchaseMetadata
                                                            ?.communicationPincode ??
                                                            'N/A'}
                                                    </td>
                                                </tr>
                                            </thead>
                                        </Table>
                                    </>
                                )}
                        </Row>
                        <Row style={{ padding: '20px' }}>
                            <h5>Cover Details</h5>
                            <Table bordered hover>
                                <thead>
                                    <tr>
                                        <th colSpan={2}>RSMD</th>
                                        <td
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <img
                                                className={styles.tickCrossIcon}
                                                src={
                                                    standardCover?.find(
                                                        (x: any) =>
                                                            x?.coverName ===
                                                            'RSMD Cover',
                                                    )?.required === true
                                                        ? tickIcon
                                                        : crossIcon
                                                }
                                            />
                                        </td>
                                        <th colSpan={2}>STFI</th>
                                        <td
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <img
                                                className={styles.tickCrossIcon}
                                                src={
                                                    standardCover?.find(
                                                        (x: any) =>
                                                            x?.coverName ===
                                                            'STFI Cover',
                                                    )?.required === true
                                                        ? tickIcon
                                                        : crossIcon
                                                }
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <th colSpan={2}>Earthquake</th>
                                        <td
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <img
                                                className={styles.tickCrossIcon}
                                                src={
                                                    standardCover?.find(
                                                        (x: any) =>
                                                            x?.coverName ===
                                                            'Earthquake Cover',
                                                    )?.required === true
                                                        ? tickIcon
                                                        : crossIcon
                                                }
                                            />
                                        </td>
                                        <th colSpan={2}>Terrorism</th>
                                        <td
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <img
                                                className={styles.tickCrossIcon}
                                                src={
                                                    standardCover?.find(
                                                        (x: any) =>
                                                            x?.coverName ===
                                                            'Terrorism Cover',
                                                    )?.required === true
                                                        ? tickIcon
                                                        : crossIcon
                                                }
                                            />
                                        </td>
                                    </tr>
                                </thead>
                            </Table>
                            <h5>Sum Insured</h5>
                            <Table bordered hover>
                                <thead>
                                    <tr style={{ textAlign: 'center' }}>
                                        <th colSpan={2}>Valuation Type</th>
                                        <th colSpan={4}>Value</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {!policyDetails?.purchasePolicyDetails?.cover?.riskName.includes(
                                        'Stock',
                                    ) && (
                                            <>
                                                <tr>
                                                    <td colSpan={2}>
                                                        Building Including Plinth &
                                                        Foundation
                                                    </td>
                                                    <td
                                                        colSpan={4}
                                                        style={{
                                                            textAlign: 'right',
                                                        }}
                                                    >
                                                        {NumberFormat(
                                                            risks
                                                                ?.map((risk: any) =>
                                                                    risk.riskName ===
                                                                        'Building Including Plinth & Foundation'
                                                                        ? risk.sumInsured ??
                                                                        0
                                                                        : 0,
                                                                )
                                                                ?.reduce(
                                                                    (
                                                                        a: any,
                                                                        b: any,
                                                                    ) => a + b,
                                                                    0,
                                                                ) ?? 0,
                                                        ) ?? '-'}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={2}>Contents</td>
                                                    <td
                                                        colSpan={4}
                                                        style={{
                                                            textAlign: 'right',
                                                        }}
                                                    >
                                                        {NumberFormat(
                                                            risks
                                                                ?.map((risk: any) =>
                                                                    risk.riskName ===
                                                                        'Contents'
                                                                        ? risk.sumInsured ??
                                                                        0
                                                                        : 0,
                                                                )
                                                                ?.reduce(
                                                                    (
                                                                        a: any,
                                                                        b: any,
                                                                    ) => a + b,
                                                                    0,
                                                                ) ?? 0,
                                                        ) ?? '-'}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={2}>
                                                        Furniture Fixture & Fittings
                                                    </td>
                                                    <td
                                                        colSpan={4}
                                                        style={{
                                                            textAlign: 'right',
                                                        }}
                                                    >
                                                        {NumberFormat(
                                                            risks
                                                                ?.map((risk: any) =>
                                                                    risk.riskName ===
                                                                        'Furniture Fixture & Fittings'
                                                                        ? risk.sumInsured ??
                                                                        0
                                                                        : 0,
                                                                )
                                                                ?.reduce(
                                                                    (
                                                                        a: any,
                                                                        b: any,
                                                                    ) => a + b,
                                                                    0,
                                                                ) ?? 0,
                                                        ) ?? '-'}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={2}>
                                                        Plant & Machinery
                                                    </td>
                                                    <td
                                                        colSpan={4}
                                                        style={{
                                                            textAlign: 'right',
                                                        }}
                                                    >
                                                        {NumberFormat(
                                                            risks
                                                                ?.map((risk: any) =>
                                                                    risk.riskName ===
                                                                        'Plant & Machinery'
                                                                        ? risk.sumInsured ??
                                                                        0
                                                                        : 0,
                                                                )
                                                                ?.reduce(
                                                                    (
                                                                        a: any,
                                                                        b: any,
                                                                    ) => a + b,
                                                                    0,
                                                                ) ?? 0,
                                                        ) ?? '-'}
                                                    </td>
                                                </tr>
                                            </>
                                        )}

                                    {policyDetails?.purchasePolicyDetails?.cover?.riskName.includes(
                                        'Stock',
                                    ) && (
                                            <>
                                                <tr>
                                                    <td colSpan={2}>
                                                        Raw Material
                                                    </td>
                                                    <td
                                                        colSpan={4}
                                                        style={{
                                                            textAlign: 'right',
                                                        }}
                                                    >
                                                        {NumberFormat(
                                                            risks
                                                                ?.map((risk: any) =>
                                                                    risk.riskName ===
                                                                        'Raw Material'
                                                                        ? risk.sumInsured ??
                                                                        0
                                                                        : 0,
                                                                )
                                                                ?.reduce(
                                                                    (
                                                                        a: any,
                                                                        b: any,
                                                                    ) => a + b,
                                                                    0,
                                                                ) ?? 0,
                                                        ) ?? '-'}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={2}>
                                                        Stocks in Process
                                                    </td>
                                                    <td
                                                        colSpan={4}
                                                        style={{
                                                            textAlign: 'right',
                                                        }}
                                                    >
                                                        {NumberFormat(
                                                            risks
                                                                ?.map((risk: any) =>
                                                                    risk.riskName ===
                                                                        'Stocks in Process'
                                                                        ? risk.sumInsured ??
                                                                        0
                                                                        : 0,
                                                                )
                                                                ?.reduce(
                                                                    (
                                                                        a: any,
                                                                        b: any,
                                                                    ) => a + b,
                                                                    0,
                                                                ) ?? 0,
                                                        ) ?? '-'}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={2}>
                                                        Finished Stocks
                                                    </td>
                                                    <td
                                                        colSpan={4}
                                                        style={{
                                                            textAlign: 'right',
                                                        }}
                                                    >
                                                        {NumberFormat(
                                                            risks
                                                                ?.map((risk: any) =>
                                                                    risk.riskName ===
                                                                        'Finished Stocks'
                                                                        ? risk.sumInsured ??
                                                                        0
                                                                        : 0,
                                                                )
                                                                ?.reduce(
                                                                    (
                                                                        a: any,
                                                                        b: any,
                                                                    ) => a + b,
                                                                    0,
                                                                ) ?? 0,
                                                        ) ?? '-'}
                                                    </td>
                                                </tr>
                                            </>
                                        )}
                                </tbody>
                                <thead>
                                    <tr>
                                        <th colSpan={2}>Total</th>
                                        <th
                                            colSpan={4}
                                            style={{ textAlign: 'right' }}
                                        >
                                            {NumberFormat(risksTotal ?? 0) ??
                                                '-'}
                                        </th>
                                    </tr>
                                </thead>
                            </Table>
                            <h5>Premium Details</h5>
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th colSpan={2}>Start Date</th>
                                        <td colSpan={2}>
                                            {dateFormat(
                                                policyDetails
                                                    ?.purchasePolicyDetails
                                                    ?.purchasedCover
                                                    ?.merchantQuote
                                                    ?.responseMetadata
                                                    ?.policyStartDate,
                                            )}
                                        </td>
                                        <th colSpan={2}>End Date</th>
                                        <td colSpan={2}>
                                            {dateFormat(
                                                policyDetails
                                                    ?.purchasePolicyDetails
                                                    ?.purchasedCover
                                                    ?.merchantQuote
                                                    ?.responseMetadata
                                                    ?.policyEndDate,
                                            )}
                                        </td>
                                    </tr>
                                </thead>
                            </Table>
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th
                                            colSpan={
                                                standardCover?.find(
                                                    (x: any) =>
                                                        x?.coverName ===
                                                        'Terrorism Cover',
                                                )?.required === true
                                                    ? 10
                                                    : 8
                                            }
                                            style={{
                                                textAlign: 'center',
                                            }}
                                        >
                                            Amounts
                                        </th>
                                    </tr>
                                </thead>
                                <thead>
                                    <tr>
                                        <th
                                            colSpan={2}
                                            style={{ textAlign: 'center' }}
                                        >
                                            Base
                                        </th>
                                        {standardCover?.find(
                                            (x: any) =>
                                                x?.coverName ===
                                                'Terrorism Cover',
                                        )?.required === true && (
                                                <th
                                                    colSpan={2}
                                                    style={{ textAlign: 'center' }}
                                                >
                                                    Terrorism
                                                </th>
                                            )}
                                        <th
                                            colSpan={2}
                                            style={{ textAlign: 'center' }}
                                        >
                                            Gross
                                        </th>
                                        <th
                                            colSpan={2}
                                            style={{ textAlign: 'center' }}
                                        >
                                            Taxes & Cess
                                        </th>
                                        <th
                                            colSpan={2}
                                            style={{ textAlign: 'center' }}
                                        >
                                            Total Amount
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td
                                            colSpan={2}
                                            style={{ textAlign: 'center' }}
                                        >
                                            {NumberFormat(
                                                policyDetails
                                                    ?.purchasePolicyDetails
                                                    ?.purchasedCover
                                                    ?.merchantQuote
                                                    ?.responseMetadata
                                                    ?.quotes?.[0]
                                                    ?.grossPremiumBreakup
                                                    ?.totalLocationPremium ?? 0,
                                            )}
                                        </td>
                                        {standardCover?.find(
                                            (x: any) =>
                                                x?.coverName ===
                                                'Terrorism Cover',
                                        )?.required === true && (
                                                <td
                                                    colSpan={2}
                                                    style={{ textAlign: 'center' }}
                                                >
                                                    {NumberFormat(
                                                        policyDetails
                                                            ?.purchasePolicyDetails
                                                            ?.purchasedCover
                                                            ?.merchantQuote
                                                            ?.responseMetadata
                                                            ?.quotes?.[0]
                                                            ?.grossPremiumBreakup
                                                            ?.totalTerrorismPremium ??
                                                        0,
                                                    )}
                                                </td>
                                            )}
                                        <td
                                            colSpan={2}
                                            style={{ textAlign: 'center' }}
                                        >
                                            {NumberFormat(
                                                (policyDetails
                                                    ?.purchasePolicyDetails
                                                    ?.purchasedCover
                                                    ?.merchantQuote
                                                    ?.responseMetadata
                                                    ?.quotes?.[0]
                                                    ?.grossPremiumBreakup
                                                    ?.totalTerrorismPremium ??
                                                    0) +
                                                (policyDetails
                                                    ?.purchasePolicyDetails
                                                    ?.purchasedCover
                                                    ?.merchantQuote
                                                    ?.responseMetadata
                                                    ?.quotes?.[0]
                                                    ?.grossPremiumBreakup
                                                    ?.totalLocationPremium ??
                                                    0) ?? 0,
                                            )}
                                        </td>
                                        <td
                                            colSpan={2}
                                            style={{ textAlign: 'center' }}
                                        >
                                            {NumberFormat(
                                                policyDetails
                                                    ?.purchasePolicyDetails
                                                    ?.purchasedCover
                                                    ?.merchantQuote
                                                    ?.responseMetadata
                                                    ?.quotes?.[0]
                                                    ?.grossPremiumBreakup
                                                    ?.totalTaxOnPremium ?? 0,
                                            )}
                                        </td>
                                        <td
                                            colSpan={2}
                                            style={{ textAlign: 'center' }}
                                        >
                                            {NumberFormat(
                                                policyDetails
                                                    ?.purchasePolicyDetails
                                                    ?.purchasedCover
                                                    ?.merchantQuote
                                                    ?.responseMetadata
                                                    ?.quotes?.[0]
                                                    ?.grossPremium ?? 0,
                                            )}
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Row>
                        <Row style={{ padding: '20px' }}>
                            <h5>Insured Locations</h5>
                            {policyDetails?.purchasePolicyDetails?.purchasedCover?.merchantQuote?.requestMetadata?.locationBasedRiskAndCoverPackages?.map(
                                (location: any, index: number) => (
                                    <Table bordered hover key={index}>
                                        <thead>
                                            <tr>
                                                <th colSpan={2}>
                                                    Location Name
                                                </th>
                                                <td colSpan={4}>
                                                    {location?.riskLocation
                                                        ?.locationName ?? 'N/A'}
                                                </td>
                                                <th colSpan={1}>
                                                    Location Number
                                                </th>
                                                <td colSpan={1}>{index + 1}</td>
                                            </tr>
                                        </thead>
                                        <thead>
                                            <tr>
                                                <th colSpan={2}>
                                                    Address Line 1
                                                </th>
                                                <td colSpan={2}>
                                                    {location?.riskLocation
                                                        ?.location_address ??
                                                        'N/A'}
                                                </td>
                                                <th colSpan={2}>
                                                    Address Line 2
                                                </th>
                                                <td colSpan={2}>
                                                    {location?.riskLocation
                                                        ?.location_address2 ??
                                                        'N/A'}
                                                </td>
                                            </tr>
                                        </thead>
                                        <thead>
                                            <tr>
                                                <th>City</th>
                                                <td>
                                                    {location?.riskLocation
                                                        ?.location_city ??
                                                        'N/A'}
                                                </td>
                                                <th>State</th>
                                                <td>
                                                    {location?.riskLocation
                                                        ?.location_state ??
                                                        'N/A'}
                                                </td>
                                                <th>Country</th>
                                                <td>
                                                    {location?.riskLocation
                                                        ?.location_country ??
                                                        'N/A'}
                                                </td>
                                                <th>Pincode</th>
                                                <td>
                                                    {location?.riskLocation
                                                        ?.pincode ??
                                                        'N/A'}
                                                </td>
                                            </tr>
                                        </thead>
                                        <thead>
                                            <tr>
                                                <th colSpan={7}>
                                                    Different communication
                                                    Address
                                                </th>

                                                <td
                                                    colSpan={2}
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent:
                                                            'center',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <img
                                                        className={
                                                            styles.tickCrossIcon
                                                        }
                                                        src={
                                                            location
                                                                ?.riskLocation
                                                                ?.differentLocationCommunicationAddress ===
                                                                true
                                                                ? tickIcon
                                                                : crossIcon
                                                        }
                                                        style={{
                                                            height: '25px',
                                                            width: '25px',
                                                        }}
                                                    />
                                                </td>
                                            </tr>
                                        </thead>
                                        {location?.riskLocation
                                            ?.differentLocationCommunicationAddress ===
                                            true && (
                                                <>
                                                    <thead>
                                                        <tr>
                                                            <th
                                                                colSpan={8}
                                                                style={{
                                                                    textAlign:
                                                                        'center',
                                                                }}
                                                            >
                                                                Communication
                                                                Address
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <thead>
                                                        <tr>
                                                            <th colSpan={2}>
                                                                Business Name
                                                            </th>
                                                            <td colSpan={2}>
                                                                {location
                                                                    ?.riskLocation
                                                                    ?.communicationLocationAddressFinancierBusinessName ??
                                                                    'N/A'}
                                                            </td>
                                                            <th colSpan={2}>
                                                                Agreement
                                                            </th>
                                                            <td colSpan={2}>
                                                                {location
                                                                    ?.riskLocation
                                                                    ?.communicationLocationAddressAgreement ??
                                                                    'N/A'}
                                                            </td>
                                                        </tr>
                                                    </thead>
                                                    {location?.riskLocation
                                                        ?.communicationLocationAddressAgreement && (
                                                            <thead>
                                                                <tr>
                                                                    <th colSpan={2}>
                                                                        Start Date
                                                                    </th>
                                                                    <td colSpan={2}>
                                                                        {dateFormat(
                                                                            location
                                                                                ?.riskLocation
                                                                                ?.communicationLocationAddressStartDate,
                                                                        ) ?? 'N/A'}
                                                                    </td>
                                                                    <th colSpan={2}>
                                                                        End Date
                                                                    </th>
                                                                    <td colSpan={2}>
                                                                        {dateFormat(
                                                                            location
                                                                                ?.riskLocation
                                                                                ?.communicationLocationAddressEndDate,
                                                                        ) ?? 'N/A'}
                                                                    </td>
                                                                </tr>
                                                            </thead>
                                                        )}
                                                    <thead>
                                                        <tr>
                                                            <th colSpan={2}>
                                                                Financier Name
                                                            </th>
                                                            <td colSpan={2}>
                                                                {location
                                                                    ?.riskLocation
                                                                    ?.communicationLocationAddressFinancierName ??
                                                                    'N/A'}
                                                            </td>
                                                            <th colSpan={2}>
                                                                Role
                                                            </th>
                                                            <td colSpan={2}>
                                                                {location
                                                                    ?.riskLocation
                                                                    ?.communicationLocationAddressFinancierDesignation ??
                                                                    'N/A'}
                                                            </td>
                                                        </tr>
                                                    </thead>
                                                    <thead>
                                                        <tr>
                                                            <th colSpan={2}>
                                                                Address Line 1
                                                            </th>
                                                            <td colSpan={2}>
                                                                {location
                                                                    ?.riskLocation
                                                                    ?.communicationLocationAddressFinancierAddressLine1 ??
                                                                    'N/A'}
                                                            </td>
                                                            <th colSpan={2}>
                                                                Address Line 2
                                                            </th>
                                                            <td colSpan={2}>
                                                                {location
                                                                    ?.riskLocation
                                                                    ?.communicationLocationAddressFinancierAddressLine2 ??
                                                                    'N/A'}
                                                            </td>
                                                        </tr>
                                                    </thead>
                                                    <thead>
                                                        <tr>
                                                            <th>City</th>
                                                            <td>
                                                                {location
                                                                    ?.riskLocation
                                                                    ?.communicationLocationAddressFinancierCity ??
                                                                    'N/A'}
                                                            </td>
                                                            <th>State</th>
                                                            <td>
                                                                {location
                                                                    ?.riskLocation
                                                                    ?.communicationLocationAddressFinancierState ??
                                                                    'N/A'}
                                                            </td>
                                                            <th>Country</th>
                                                            <td>
                                                                {location
                                                                    ?.riskLocation
                                                                    ?.communicationLocationAddressFinancierCountry ??
                                                                    'N/A'}
                                                            </td>
                                                            <th>Pincode</th>
                                                            <td>
                                                                {location
                                                                    ?.riskLocation
                                                                    ?.communicationLocationAddressFinancierPincode ??
                                                                    'N/A'}
                                                            </td>
                                                        </tr>
                                                    </thead>
                                                </>
                                            )}
                                        <thead>
                                            <tr>
                                                <th
                                                    colSpan={8}
                                                    style={{
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    Sum Insured
                                                </th>
                                            </tr>
                                        </thead>
                                        <thead>
                                            <tr style={{ textAlign: 'center' }}>
                                                <th colSpan={6}>
                                                    Valuation Type
                                                </th>
                                                <th colSpan={2}>Value</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {!policyDetails?.purchasePolicyDetails?.cover?.riskName.includes(
                                                'Stock',
                                            ) && (
                                                    <>
                                                        <tr>
                                                            <td colSpan={6}>
                                                                Building Including
                                                                Plinth & Foundation
                                                            </td>
                                                            <td
                                                                colSpan={2}
                                                                style={{
                                                                    textAlign:
                                                                        'right',
                                                                }}
                                                            >
                                                                {NumberFormat(
                                                                    location?.risks?.find(
                                                                        (
                                                                            risk: any,
                                                                        ) =>
                                                                            risk.riskName ===
                                                                            'Building Including Plinth & Foundation',
                                                                    )?.sumInsured ??
                                                                    0,
                                                                ) ?? '-'}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan={6}>
                                                                Contents
                                                            </td>
                                                            <td
                                                                colSpan={2}
                                                                style={{
                                                                    textAlign:
                                                                        'right',
                                                                }}
                                                            >
                                                                {NumberFormat(
                                                                    location?.risks?.find(
                                                                        (
                                                                            risk: any,
                                                                        ) =>
                                                                            risk.riskName ===
                                                                            'Contents',
                                                                    )?.sumInsured ??
                                                                    0,
                                                                ) ?? '-'}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan={6}>
                                                                Furniture Fixture &
                                                                Fittings
                                                            </td>
                                                            <td
                                                                colSpan={2}
                                                                style={{
                                                                    textAlign:
                                                                        'right',
                                                                }}
                                                            >
                                                                {NumberFormat(
                                                                    location?.risks?.find(
                                                                        (
                                                                            risk: any,
                                                                        ) =>
                                                                            risk.riskName ===
                                                                            'Furniture Fixture & Fittings',
                                                                    )?.sumInsured ??
                                                                    0,
                                                                ) ?? '-'}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan={6}>
                                                                Plant & Machinery
                                                            </td>
                                                            <td
                                                                colSpan={2}
                                                                style={{
                                                                    textAlign:
                                                                        'right',
                                                                }}
                                                            >
                                                                {NumberFormat(
                                                                    location?.risks?.find(
                                                                        (
                                                                            risk: any,
                                                                        ) =>
                                                                            risk.riskName ===
                                                                            'Plant & Machinery',
                                                                    )?.sumInsured ??
                                                                    0,
                                                                ) ?? '-'}
                                                            </td>
                                                        </tr>
                                                    </>
                                                )}

                                            {policyDetails?.purchasePolicyDetails?.cover?.riskName.includes(
                                                'Stock',
                                            ) && (
                                                    <>
                                                        <tr>
                                                            <td colSpan={6}>
                                                                Raw Material
                                                            </td>
                                                            <td
                                                                colSpan={2}
                                                                style={{
                                                                    textAlign:
                                                                        'right',
                                                                }}
                                                            >
                                                                {NumberFormat(
                                                                    location?.risks?.find(
                                                                        (
                                                                            risk: any,
                                                                        ) =>
                                                                            risk.riskName ===
                                                                            'Raw Material',
                                                                    )?.sumInsured ??
                                                                    0,
                                                                ) ?? '-'}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan={6}>
                                                                Stocks in Process
                                                            </td>
                                                            <td
                                                                colSpan={2}
                                                                style={{
                                                                    textAlign:
                                                                        'right',
                                                                }}
                                                            >
                                                                {NumberFormat(
                                                                    location?.risks?.find(
                                                                        (
                                                                            risk: any,
                                                                        ) =>
                                                                            risk.riskName ===
                                                                            'Stocks in Process',
                                                                    )?.sumInsured ??
                                                                    0,
                                                                ) ?? '-'}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan={6}>
                                                                Finished Stocks
                                                            </td>
                                                            <td
                                                                colSpan={2}
                                                                style={{
                                                                    textAlign:
                                                                        'right',
                                                                }}
                                                            >
                                                                {NumberFormat(
                                                                    location?.risks?.find(
                                                                        (
                                                                            risk: any,
                                                                        ) =>
                                                                            risk.riskName ===
                                                                            'Finished Stocks',
                                                                    )?.sumInsured ??
                                                                    0,
                                                                ) ?? '-'}
                                                            </td>
                                                        </tr>
                                                    </>
                                                )}
                                        </tbody>
                                        <thead>
                                            <tr>
                                                <th colSpan={6}>Total</th>
                                                <th
                                                    colSpan={2}
                                                    style={{
                                                        textAlign: 'right',
                                                    }}
                                                >
                                                    {NumberFormat(
                                                        location?.risks
                                                            ?.map((risk: any) =>
                                                                (policyDetails?.purchasePolicyDetails?.cover?.riskName.includes(
                                                                    'Stock',
                                                                )
                                                                    ? [
                                                                        'Raw Material',
                                                                        'Stocks in Process',
                                                                        'Finished Stocks',
                                                                    ]
                                                                    : [
                                                                        'Building Including Plinth & Foundation',
                                                                        'Contents',
                                                                        'Furniture Fixture & Fittings',
                                                                        'Plant & Machinery',
                                                                    ]
                                                                ).includes(
                                                                    risk?.riskName,
                                                                )
                                                                    ? risk?.sumInsured ??
                                                                    0
                                                                    : 0,
                                                            )
                                                            ?.reduce(
                                                                (
                                                                    a: any,
                                                                    b: any,
                                                                ) => a + b,
                                                                0,
                                                            ) ?? 0,
                                                    ) ?? '-'}
                                                </th>
                                            </tr>
                                        </thead>
                                        <thead>
                                            <tr>
                                                <th
                                                    colSpan={8}
                                                    style={{
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    Premium Breakup
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {policyDetails?.purchasePolicyDetails?.purchasedCover?.merchantQuote?.responseMetadata?.quotes?.[0]?.premiumBreakupByLocations?.find(
                                                (premiumBreakup: any) =>
                                                    premiumBreakup?.locationId ===
                                                    location?.locationId,
                                            ) ? (
                                                <>
                                                    <tr>
                                                        <td colSpan={6}>
                                                            Location Premium
                                                        </td>
                                                        <td
                                                            colSpan={2}
                                                            style={{
                                                                textAlign:
                                                                    'right',
                                                            }}
                                                        >
                                                            {NumberFormat(
                                                                policyDetails?.purchasePolicyDetails?.purchasedCover?.merchantQuote?.responseMetadata?.quotes?.[0]?.premiumBreakupByLocations?.find(
                                                                    (
                                                                        premiumBreakup: any,
                                                                    ) =>
                                                                        premiumBreakup?.locationId ===
                                                                        location?.locationId,
                                                                )
                                                                    ?.locationPremium ??
                                                                0,
                                                            )}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan={6}>
                                                            Terrorism Premium
                                                        </td>
                                                        <td
                                                            colSpan={2}
                                                            style={{
                                                                textAlign:
                                                                    'right',
                                                            }}
                                                        >
                                                            {NumberFormat(
                                                                policyDetails?.purchasePolicyDetails?.purchasedCover?.merchantQuote?.responseMetadata?.quotes?.[0]?.premiumBreakupByLocations?.find(
                                                                    (
                                                                        premiumBreakup: any,
                                                                    ) =>
                                                                        premiumBreakup?.locationId ===
                                                                        location?.locationId,
                                                                )
                                                                    ?.terrorismPremium ??
                                                                0,
                                                            )}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan={6}>
                                                            Tax on Premium
                                                        </td>
                                                        <td
                                                            colSpan={2}
                                                            style={{
                                                                textAlign:
                                                                    'right',
                                                            }}
                                                        >
                                                            {NumberFormat(
                                                                policyDetails?.purchasePolicyDetails?.purchasedCover?.merchantQuote?.responseMetadata?.quotes?.[0]?.premiumBreakupByLocations?.find(
                                                                    (
                                                                        premiumBreakup: any,
                                                                    ) =>
                                                                        premiumBreakup?.locationId ===
                                                                        location?.locationId,
                                                                )
                                                                    ?.taxOnPremium ??
                                                                0,
                                                            )}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th colSpan={6}>
                                                            Total Premium
                                                        </th>
                                                        <th
                                                            colSpan={2}
                                                            style={{
                                                                textAlign:
                                                                    'right',
                                                            }}
                                                        >
                                                            {NumberFormat(
                                                                policyDetails?.purchasePolicyDetails?.purchasedCover?.merchantQuote?.responseMetadata?.quotes?.[0]?.premiumBreakupByLocations?.find(
                                                                    (
                                                                        premiumBreakup: any,
                                                                    ) =>
                                                                        premiumBreakup?.locationId ===
                                                                        location?.locationId,
                                                                )
                                                                    ?.totalPremium ??
                                                                0,
                                                            )}
                                                        </th>
                                                    </tr>
                                                </>
                                            ) : (
                                                <></>
                                            )}
                                        </tbody>
                                    </Table>
                                ),
                            )}
                        </Row>
                        <Row style={{ padding: '20px' }}>
                            <h5>KYC Details</h5>
                            <Table bordered hover>
                                <thead>
                                    <tr>
                                        <th>Email</th>
                                        <td colSpan={2}>
                                            {policyDetails?.kycDetails
                                                ?.insured_user_detail?.email ??
                                                'N/A'}
                                        </td>
                                        <th>Phone</th>
                                        <td colSpan={2}>
                                            {policyDetails?.kycDetails
                                                ?.insured_user_detail
                                                ?.mobile_number ?? 'N/A'}
                                        </td>
                                    </tr>
                                </thead>
                                <thead>
                                    <tr>
                                        <th>GST Number</th>
                                        <td colSpan={2}>
                                            {policyDetails?.kycDetails
                                                ?.business_gst?.gst_number ??
                                                'N/A'}
                                        </td>
                                        <th>PAN Number</th>
                                        <td colSpan={2}>
                                            {policyDetails?.kycDetails
                                                ?.business_pan?.pan_number ??
                                                'N/A'}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th colSpan={3}>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                {policyDetails?.gst_file ? policyDetails?.kycDetails
                                                    ?.business_gst?.gst_file_type === 'application/pdf' ? (<HandlePdfFile url={policyDetails?.gst_file as string} docType="GST" />) : (
                                                    <img
                                                        src={
                                                            policyDetails?.gst_file
                                                        }
                                                        style={{
                                                            width: '200px',
                                                            height: '200px',
                                                        }}
                                                    />
                                                ) : (
                                                    'N/A'
                                                )}
                                            </div>
                                        </th>
                                        <th colSpan={3}>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                {policyDetails?.pan_file ?
                                                    (policyDetails?.kycDetails
                                                        ?.business_pan?.pan_file_type
                                                        === 'application/pdf' ? (<HandlePdfFile url={policyDetails?.pan_file as string} docType="PAN" />) :
                                                        (<img
                                                            src={
                                                                policyDetails?.pan_file
                                                            }
                                                            style={{
                                                                width: '200px',
                                                                height: '200px',
                                                            }}
                                                        />
                                                        )) : (
                                                        'N/A'
                                                    )}
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                            </Table>
                        </Row>
                    </div>
                )}
            </Modal.Body>
        </Modal>
    );
};

export default PolicyDetailsModal;
