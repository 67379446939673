import styles from './GhUsers.module.scss';

import {
    createColumnHelper,
    flexRender,
    getCoreRowModel,
    useReactTable,
} from '@tanstack/react-table';
import axios from 'axios';
import React, { useEffect, useMemo, useState } from 'react';
import { notify } from '../../common/Toast';
import PolicyStepDetails from '../../common/PolicyStepDetails/PolicyStepDetails';
import viewIcon from '../../assets/images/viewIcon.svg';
import GhStepDetails from '../../common/GhStepDetails/GhStepDetails';

const columnHelper = createColumnHelper();

function useDataAPI({
    pagination: { limit = 10, skip = 0 } = {},
    sort: { field = 'id', order = 'DESC' } = {},
    filter: { channel = '' } = {},
} = {}) {
    const [data, setData] = useState([]);
    const [count, setCount] = useState(0);
    const [loading, setLoading] = useState(false);

    const handleApiCall = async () => {
        setLoading(true);

        const authData = JSON.parse(
            localStorage.getItem('authCheck') ?? 'null',
        );
        const headers = {
            Authorization: `Bearer ${authData.adminAuth}`,
        };

        const body = {
            pagination: {
                limit,
                skip,
            },
            sort: {
                field,
                order,
            },
            filter: {
                channel, // Include the channel in the filter
            },
        };
        const response = await axios.post(
            `${process.env.REACT_APP_GH_API_URL}/admin/ghuser-list-with-step`,
            body,
            { headers },
        );

        if (response.status === 200) {
            const userData = response.data?.data?.users ?? [];
            const userDataWithCompany = userData.map((user: any) => {
                return {
                    ...user,
                    company:
                        user?.request_metadata?.onBoardingData?.personalDetails
                            ?.company_name ?? 'N/A',
                };
            });
            setData(userDataWithCompany);
            setCount(response.data?.data?.totalCount ?? 0);
        } else {
            setData([]);
            setCount(0);
            notify.error('Something went wrong fetching users list');
        }

        setLoading(false);
    };

    useEffect(() => {
        handleApiCall();
    }, [limit, skip, field, order, channel, setData, setLoading]);

    return [data, count, loading];
}
function usePagination(initialSize = 10) {
    const [pagination, setPagination] = useState({
        pageSize: initialSize,
        pageIndex: 0,
    });
    const { pageSize, pageIndex } = pagination;

    return {
        // table state
        onPaginationChange: setPagination,
        pagination,
        // API
        limit: pageSize,
        skip: pageSize * pageIndex,
    };
}

function useSorting(initialField = 'id', initialOrder = 'DESC') {
    const [sorting, setSorting] = useState([
        { id: initialField, desc: initialOrder === 'DESC' },
    ]);

    return {
        sorting,
        onSortingChange: setSorting,
        order: !sorting.length
            ? initialOrder
            : sorting[0].desc
            ? 'DESC'
            : 'ASC',
        field: sorting.length ? sorting[0].id : initialField,
    };
}

export const Table = ({
    cols,
    data,
    loading,
    onPaginationChange,
    onSortingChange,
    pageCount,
    pagination,
    sorting,
}: any) => {
    const columns = useMemo(
        () =>
            cols.map(({ id, header, enableSorting, cell }: any) => ({
                ...columnHelper.accessor(id, {
                    header,
                }),
                cell,
                enableSorting,
            })),
        [cols],
    );

    const tableLib = useReactTable({
        data,
        columns,
        getCoreRowModel: getCoreRowModel(),
        manualPagination: true,
        manualSorting: true,
        onPaginationChange,
        onSortingChange,
        state: { pagination, sorting },
        pageCount,
    });
    return (
        <section>
            <table className="table">
                <thead>
                    {tableLib.getHeaderGroups().map((headerGroup) => (
                        <tr key={headerGroup.id}>
                            {headerGroup.headers.map((header) => (
                                <th
                                    key={header.id}
                                    {...(header.column.getCanSort()
                                        ? {
                                              onClick:
                                                  header.column.getToggleSortingHandler(),
                                          }
                                        : {})}
                                >
                                    {flexRender(
                                        header.column.columnDef.header,
                                        header.getContext(),
                                    )}

                                    {header.column.getIsSorted() === 'asc' ? (
                                        <span> 🡡</span>
                                    ) : header.column.getIsSorted() ===
                                      'desc' ? (
                                        <span> 🡣</span>
                                    ) : null}
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody>
                    {loading ? (
                        <tr>
                            <td colSpan={100}>
                                <Loader />
                            </td>
                        </tr>
                    ) : (
                        tableLib.getRowModel().rows.map((row) => (
                            <tr key={row.id}>
                                {row.getVisibleCells().map((cell) => (
                                    <td key={cell.id}>
                                        {flexRender(
                                            cell.column.columnDef.cell,
                                            cell.getContext(),
                                        )}
                                    </td>
                                ))}
                            </tr>
                        ))
                    )}
                </tbody>
            </table>
            <Pagination tableLib={tableLib} sizes={[10, 20, 50]} />
        </section>
    );
};

export const Pagination = ({ tableLib, sizes }: any) => (
    <footer className="pagination" style={{ marginBottom: '20px' }}>
        <div style={{ display: 'flex', gap: '10px', width: '80%' }}>
            <button
                disabled={!tableLib.getCanPreviousPage()}
                onClick={() => tableLib.setPageIndex(0)}
            >
                ⯬
            </button>
            <button
                disabled={!tableLib.getCanPreviousPage()}
                onClick={tableLib.previousPage}
            >
                🡠
            </button>
            <span>{`page ${
                tableLib.getState().pagination.pageIndex + 1
            } of ${tableLib.getPageCount()}`}</span>
            <button
                disabled={!tableLib.getCanNextPage()}
                onClick={tableLib.nextPage}
            >
                🡢
            </button>
            <button
                disabled={!tableLib.getCanNextPage()}
                onClick={() =>
                    tableLib.setPageIndex(tableLib.getPageCount() - 1)
                }
            >
                ⯮
            </button>
        </div>
        <div style={{ display: 'flex', gap: '10px' }}>
            <span>Show: </span>
            <select
                value={tableLib.getState().pageSize}
                onChange={(e) =>
                    tableLib.setPageSize(parseInt(e.target.value, 10))
                }
            >
                {sizes.map((size: any) => (
                    <option key={size} value={size}>
                        {size}
                    </option>
                ))}
            </select>
            <span> items per page</span>
        </div>
    </footer>
);

const Loader = () => <div className="loader">⏳</div>;

const stepNameByNumber = (
    stepNumber: number,
    insurance_type: string,
): string => {

    //console.log('stepNumber ==> ', stepNumber);

    const step = insurance_type === 'fire' ? stepNumber - 1 : stepNumber;

    switch (step) {
        case 0:
            return 'Home Page';
        case 1:
            return 'Company Details';
        case 2:
            return 'Coverage Details';
        case 3:
            return 'Employee Details';
        case 4:
            return 'Recommendation & Review';
        case 5:
            return 'Checkout';
        case 6:
            return 'Bank Details';
        // case 8:
        //     return 'Sum Insured';
        // case 9:
        //     return 'Sum Insured';
        // case 10:
        //     return 'Sum Insured';
        // case 11:
        //     return 'Personal Details';
        // case 12:
        //     return 'Insured Location Details';
        // case 13:
        //     return 'Payment Page';
        // case 14:
        //     return 'Policy Start Date';
        // case 15:
        //     return 'KYC Page';
        // case 17:
        //     return 'Policy Purchase Complete';
    }
    return 'N/A';
};

export const GhUsers = () => {
    const [channelFilter, setChannelFilter] = useState('');

    const handleChannelFilterChange = (
        e: React.ChangeEvent<HTMLInputElement>,
    ) => {
        setChannelFilter(e.target.value);
    };

    const { limit, onPaginationChange, skip, pagination } = usePagination();

    const { sorting, onSortingChange, field, order } = useSorting();

    const [data, count, loading]: any = useDataAPI({
        pagination: { skip, limit },
        sort: { field, order },
        filter: { channel: channelFilter },
    });

    const [showModal, setShowModal] = useState(false);

    const [modalData, setModalData] = useState({});

    const handleModalOpen = (id: number) => {
        const record = data.find((item: any) => item.id === id);

        const payload = {
            ...record,
        };

        setModalData(payload);

        console.log(payload)

        setShowModal(true);
    };

    const handleModalClose = () => {
        setShowModal(false);

        setModalData({});
    };

    const cols = [
        {
            id: 'id',
            header: 'User ID',
            enableSorting: true,
            cell: ({ row }: any) => {
                return <div>{row.original.id}</div>;
            },
        },
        {
            id: 'first_name',
            header: 'First Name',
            enableSorting: true,
            cell: ({ row }: any) => {
                return <div>{row.original.first_name}</div>;
            },
        },
        {
            id: 'last_name',
            header: 'Last Name',
            enableSorting: true,
            cell: ({ row }: any) => {
                return <div>{row.original.last_name}</div>;
            },
        },
        {
            id: 'email',
            header: 'Email',
            enableSorting: true,
            cell: ({ row }: any) => {
                return (
                    <div>
                        <a href={`mailto:${row.original.email}`}>
                            {row.original.email}
                        </a>
                    </div>
                );
            },
        },
        {
            id: 'phone_number',
            header: 'Phone Number',
            enableSorting: true,
            cell: ({ row }: any) => {
                return (
                    <div>
                        <a href={`tel:${row.original.phone_number}`}>
                            {row.original.phone_number}
                        </a>
                    </div>
                );
            },
        },
        {
            id: 'channel',
            header: 'Channel',
            enableSorting: true,
            cell: ({ row }: any) => {
                return <div>{row.original.channel}</div>;
            },
        },
        {
            id: 'company',
            header: 'Company',
            enableSorting: true,
            cell: ({ row }: any) => {
                return (
                    <div>
                        {/* {row.original.company ? row.original.company : row.original.first_name} */}
                        { row.original.first_name}
                    </div>
                );
            },
        },
        {
            id: 'stepNumber',
            header: 'GH Step Details ',
            enableSorting: true,
            cell: ({ row }: any) => {
                const stepName = stepNameByNumber(
                    row.original.stepNumber ? row.original.stepNumber + 1 : 0,
                    row.original.insurance_type,
                );
                return (
                    <div style={{ display: 'flex', gap: '5px' }}>
                        <div>{stepName}</div>
                        {stepName !== 'N/A' && (
                            <img
                                src={viewIcon}
                                alt="view"
                                onClick={() => handleModalOpen(row.original.id)}
                                style={{
                                    cursor: 'pointer',
                                    marginLeft: '2px',
                                    height: '20px',
                                    width: '20px',
                                    padding: '3px',
                                }}
                            />
                        )}
                    </div>
                );
            },
        },
        {
            id: 'insurance_type',
            header: 'Insurance Type',
            enableSorting: true,
            cell: ({ row }: any) => {
                return (
                    <div>
                        {/* {row.original.insurance_type
                            ? row.original.insurance_type === 'fire'
                                ? 'Fire'
                                : 'Stock'
                            : 'N/A'} */}
                        {
                            row.original.insurance_type
                        }    
                    </div>
                );
            },
        },
        {
            id: 'industry_type',
            header: 'Industry Type',
            enableSorting: true,
            cell: ({ row }: any) => {
                return (
                    <div>
                        {/* {row.original.insurance_type
                            ? row.original.insurance_type === 'fire'
                                ? 'Fire'
                                : 'Stock'
                            : 'N/A'} */}
                        {
                            row.original.gh_company_industry
                        }    
                    </div>
                );
            },
        },
        {
            id: 'created_at',
            header: 'User Regsitration',
            enableSorting: true,
            cell: ({ row }: any) => {
                return (
                    <div>
                        {row.original.created_at
                            ? new Date(
                                  row.original.created_at,
                              )?.toLocaleDateString('en-US') +
                              '\n' +
                              new Date(
                                  row.original.created_at,
                              )?.toLocaleTimeString('en-US')
                            : 'N/A'}
                    </div>
                );
            },
        },
        {
            id: 'updated_at',
            header: 'Last Step Update',
            enableSorting: true,
            cell: ({ row }: any) => {
                return (
                    <div>
                        {row.original.step_updated_at
                            ? new Date(
                                  row.original.step_updated_at,
                              )?.toLocaleDateString('en-US') +
                              '\n' +
                              new Date(
                                  row.original.step_updated_at,
                              )?.toLocaleTimeString('en-US')
                            : 'N/A'}
                    </div>
                );
            },
        },
    ];

    const pageCount = Math.round(count / limit);

    return (
        <>
            <div style={{ marginBottom: '20px' }}>
                <label>Filter by Channel:</label>
                <input
                    type="text"
                    value={channelFilter}
                    onChange={handleChannelFilterChange}
                    placeholder="Enter channel name"
                />
            </div>
            <Table
                cols={cols}
                data={data}
                loading={loading}
                onPaginationChange={onPaginationChange}
                onSortingChange={onSortingChange}
                pageCount={pageCount}
                pagination={pagination}
                sorting={sorting}
            />
            <GhStepDetails
                show={showModal}
                onHide={handleModalClose}
                policyDetails={modalData}
            />
        </>
    );
};

export default GhUsers;
