// FormComponent.tsx
import React, { useState } from 'react';
import styles from './GhAdminTool.module.scss';
import axios from 'axios';
import 'react-toastify/dist/ReactToastify.css';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import { notify } from '../../common/Toast';
import { NumberFormat } from '../../utils/currencyConverter';
import { dateFormat } from '../../utils/dateFormat';
import { CurrencyFormatter } from '../../utils/currencyFormatter';
import editIcon from '../../assets/images/editIcon.svg';
import viewIcon from '../../assets/images/viewIcon.svg';
import UpdatepolicyModalBox from '../../common/UpdatePolicyModal';
import SupportTicketStatusUpdate from '../../common/SupportTicketStatusUpdate';
import PolicyDetailsModal from '../../common/PolicyDetailsModal';

const FormComponent = () => {
    const [email, setEmail] = useState('');
    const [found, setFound] = useState(false);
    const [openPendingPolicyModal, setOpenPendingPolicyModal] =
        useState<number>(-1);
    const [openActivePolicyModal, setOpenActivePolicyModal] =
        useState<number>(-1);

    const [user, setUser] = useState({
        id: '',
        companyId: '',
        first_name: '',
        last_name: '',
        phone: '',
        email: '',
        whatsapp: ''
    });
    const [activePolicies, setActivePolicies] = useState([]);
    const [pendingPolicies, setPendingPolicies] = useState([]);
    const [payments, setPayments] = useState([]);
    const [supportTickets, setSupportTickets] = useState([]);

    const [viewPolicyDetailsId, setViewPolicyDetailsId] = useState<
        null | number
    >(null);

    const [showPolicyDetailsModal, setShowPolicyDetailsModal] = useState(false);

    // handle policy details open
    const handlePolicyDetailsOpen = (id: number) => {
        setViewPolicyDetailsId(id);
        setShowPolicyDetailsModal(true);
    };

    // handle policy details close
    const handlePolicyDetailsModalClose = () => {
        setShowPolicyDetailsModal(false);
        setViewPolicyDetailsId(null);
    };

    // Fetch data from API 2 using the id from API 1
    const fetchPolicy = async (userId: any) => {
        const response = await axios.get(
            `${process.env.REACT_APP_BASE_URL}/v1/policies/users/${userId}?status=initiated&status=active`,
        );
        const policyArray = (response?.data.data || []).flatMap(
            (element: { policies: any[] }) =>
                element.policies
                    ? element.policies.map((ele) => {
                        const policyPurchasedData = {
                            ...element,
                            policy: ele,
                            policies: undefined, //* Fixed Type error policyPurchasedData.policies using this
                        };
                        delete policyPurchasedData.policies;
                        return policyPurchasedData;
                    })
                    : [],
        );
        const activePolicesArray = policyArray.filter((ele: any) => {
            return ele.status === 'active';
        });
        const pendingPolicesArray = policyArray.filter((ele: any) => {
            return ele.status === 'initiated';
        });
        setActivePolicies(activePolicesArray);
        setPendingPolicies(pendingPolicesArray);
    };

    const fetchPayments = async (userId: string) => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_GH_API_URL}/v1/admins/users/payments?userId=${userId}`,
            );
            setPayments(response.data.data);
        } catch (error) {
            notify.error(`${error}`);
        }
    };

    const fetchSupportTickets = async (userId: string) => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_GH_API_URL}/v1/admins/users/support-tickets?userId=${userId}`,
            );
            setSupportTickets(response.data.data);
        } catch (error) {
            notify.error(`${error}`);
        }
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        // Add your authentication logic here
        try {
            // Make a POST request to your server endpoint
            const response = await axios.get(
                `${process.env.REACT_APP_GH_API_URL}/user/get-user-profile-by-email?email=${email}`,
            );
            // const idFromApi1 = response.data.data.id;
            fetchPolicy(response.data.data.id);
            fetchPayments(response.data.data.id);
            fetchSupportTickets(response.data.data.id);

            // Handle the response as needed (e.g., set user state, redirect, etc.)
            // console.log('req sent', response.data);
            if (response.data.success) {
                setFound(true);
                setUser({
                    ...user,
                    id: response.data.data.id,
                    companyId: response.data.data.companyId,
                    first_name: response.data.data.first_name,
                    last_name: response.data.data.last_name,
                    phone: response.data.data.phone_number,
                    email: response.data.data.email,
                    whatsapp: response.data.data.whatsapp,
                });
                notify.success('User exists!');
            } else {
                notify.error("User dosen't Exist");
            }
        } catch (error) {
            // Handle errors (e.g., show error message to the user)
            console.error('Error', error);
            notify.error(`${error}`);
        }
    };

    const [openForm, setOpenForm] = useState(false);
    const [openPaymentsForm, setOpenPaymentsForm] = useState(false);
    const [startDate, setStartDate] = useState('');
    const [receiptId, setReceiptId] = useState('');
    const [endDate, setEndDate] = useState('');
    const [paymentDate, setPaymentDate] = useState('');
    const [sumInsured, setsumInsured] = useState('');
    // const [limitInsurance, setlimitInsurance] = useState('')
    // const [totalLimit, settotalLimit] = useState('')
    // const [subLimit, setsubLimit] = useState('')
    const [policyHolder, setpolicyHolder] = useState('');
    const [insurerName, setInsurerName] = useState<string>('');
    const [file, setFile] = useState<File | null>();
    const [policyOption, setPolicyOption] = useState('');
    const [policyNumber, setPolicyNumber] = useState('');
    const [optionsArray, setOptionsArray] = useState<string[]>([]);
    const [basicAmount, setBasicAmount] = useState('');
    const [tax, setTax] = useState('');
    const [grossAmount, setGrossAmount] = useState('');

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        // Check if files are present
        if (e.target.files && e.target.files[0]) {
            setFile(e.target.files[0]);
        } else {
            notify.error('No file selected');
            // console.error('No file selected');
            setFile(null); // Set fileData to null to handle the case where the user unselects the file
        }
    };

    const ICICIOptionsArray: string[] = [
        'General Liability',
        'Product Liability',
        'Cyber Liability',
        'Directors and Officers Liability',
        'Employee Dishonesty Or Crime',
        'Professional Indemnity ',
        'Fire Insurance',
        'Stock Insurance',
        'Group Health Insurance',
    ];

    const BajajOptionsArray: string[] = [
        'Cyber Protect Digital Business and Data Protection Insurance',
    ];

    const HDFCoptionsArray: string[] = [
        'Directors and Officers Liability Insurance',
        'Cyber Security Insurance Policy',
    ];

    const handleInsurerName = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setInsurerName(event.target.value);
        switch (event.target.value) {
            case 'ICICI Lombard':
                setOptionsArray(ICICIOptionsArray);
                break;
            case 'Bajaj Allianz':
                setOptionsArray(BajajOptionsArray);
                break;
            case 'HDFC Ergo':
                setOptionsArray(HDFCoptionsArray);
                break;
            default:
                setOptionsArray([]);
                break;
        }
    };

    const handlePolicyName = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setPolicyOption(event.target.value);
    };

    //Array of Insurer Names
    const insurerNameArray = ['ICICI Lombard', 'Bajaj Allianz', 'HDFC Ergo'];

    const [insuredPoints, setinsuredPoints] = useState<{
        [key: string]: string;
    }>({});
    const [keys, setKeys] = useState<string[]>([]);

    const handleAddInsueredField = () => {
        const newKey = `${keys.length + 1}`;
        setKeys([...keys, newKey]);
    };

    const handleInsured = (
        e: React.ChangeEvent<HTMLInputElement>,
        key: string,
    ) => {
        const { value } = e.target;

        setinsuredPoints((prevData) => ({ ...prevData, [key]: value }));
    };

    const [uninsuredPoints, setuinsuredPoints] = useState<{
        [key: string]: string;
    }>({});
    const [key, setKey] = useState<string[]>([]);

    const handleAddUninsuredField = () => {
        const newKey = `${key.length + 1}`;
        setKey([...key, newKey]);
    };

    const handleUninsured = (
        e: React.ChangeEvent<HTMLInputElement>,
        key: string,
    ) => {
        const { value } = e.target;
        setuinsuredPoints((prevData) => ({ ...prevData, [key]: value }));
    };

    function capitalizeFirstLetter(string: string) {
        return string.charAt(0).toUpperCase() + string.slice(1)
    }
    
    const createPolicyforOfflineUsers = async (e: React.FormEvent) => {
        e.preventDefault();
        const userId = user.id;
        const companyId = user.companyId;
        const formData = new FormData();
        formData.append('userId', userId);
        formData.append('companyId', companyId);
        formData.append('policyHolder', policyHolder);
        formData.append('policyName', policyOption);
        formData.append('startDate', startDate);
        formData.append('endDate', endDate);
        formData.append('sumInsured', CurrencyFormatter(sumInsured).toString());
        formData.append('insurerName', insurerName);
        if (file) {
            formData.append('policyPDF', file);
        }
        formData.append('insuredPoints', JSON.stringify(insuredPoints));
        formData.append('uninsuredPoints', JSON.stringify(uninsuredPoints));
        formData.append('policyNumber', policyNumber);
        formData.append('receiptId', receiptId);
        // formData.append('paymentDate', paymentDate);
        // console.log(insuredPoints,typeof(insuredPoints));
        // console.log(uninsuredPoints,typeof(uninsuredPoints));

        // console.log(policyHolder,policyId,amount,startDate,endDate,sumInsured,insurerName,limitInsurance,totalLimit,subLimit,file);
        try {
            const authData = JSON.parse(
                localStorage.getItem('authCheck') ?? 'null',
            );
            const headers = {
                Authorization: `Bearer ${authData.adminAuth}`,
            };

            await axios.post(
                `${process.env.REACT_APP_GH_API_URL}/v1/admins/users/policies`,
                formData,
                { headers },
            );
            fetchPolicy(userId);
            setOpenForm(false);

            notify.success('Policy Added!');
        } catch (error: any) {
            notify.error(`${error}`);
        }
    };

    const createPaymentforOfflineUsers = async (e: React.FormEvent) => {
        e.preventDefault();
        const userId = user.id;
        const companyId = user.companyId;
        // const formData = new FormData();
        // formData.append('userId', userId);
        // formData.append('companyId', companyId);
        // formData.append(
        //     'basicAmount',
        //     CurrencyFormatter(basicAmount).toString(),
        // );
        // formData.append('tax', CurrencyFormatter(tax).toString());
        // formData.append(
        //     'grossAmount',
        //     CurrencyFormatter(grossAmount).toString(),
        // );
        // formData.append('paymentDate', paymentDate);
        const body = {
            userId,
            companyId,
            basicAmount: CurrencyFormatter(basicAmount).toString(),
            tax: CurrencyFormatter(tax).toString(),
            grossAmount: CurrencyFormatter(grossAmount).toString(),
            paymentDate,
        };

        try {
            const authData = JSON.parse(
                localStorage.getItem('authCheck') ?? 'null',
            );
            const headers = {
                Authorization: `Bearer ${authData.adminAuth}`,
            };

            await axios.post(
                `${process.env.REACT_APP_GH_API_URL}/v1/admins/users/payment`,
                body,
                { headers },
            );
            fetchPayments(userId);
            setOpenPaymentsForm(false);

            notify.success('Payment Added!');
        } catch (error: any) {
            notify.error(`${error}`);
        }
    };
    const [selectedOption, setSelectedOption] = useState('created');
    const [editSupportTicketStatusOption, setEditSupportTicketStatusOption] =
        useState(-1);

    const handleTickClick = async (supportTicketId: string) => {
        try {
            const authData = JSON.parse(
                localStorage.getItem('authCheck') ?? 'null',
            );
            const headers = {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${authData.adminAuth}`,
            };
            const response = await axios.patch(
                `${process.env.REACT_APP_GH_API_URL}/v1/admins/users/support-ticket/${supportTicketId}`,
                {
                    status: selectedOption,
                },
                {
                    headers,
                },
            );
            const { data } = response;
            if (data.success) {
                fetchSupportTickets(user.id);
                handleCrossClick();
                notify.success(
                    `Status Updated for Support Ticket ID: ${supportTicketId}`,
                );
            }
        } catch (error) {
            notify.error('Error Updating Status!');
        }
    };

    const handleCrossClick = () => {
        setEditSupportTicketStatusOption(-1);
        setSelectedOption('');
    };

    return (
        <div>
            <form className={styles.form} onSubmit={handleSubmit}>
                <label htmlFor="email">Email:</label>
                <div className={styles.check_email}>
                    <input
                        type="email"
                        id="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />

                    <button type="submit">Check</button>
                </div>
            </form>

            {!found ? (
                <p></p>
            ) : (
                <div className={styles.tab}>
                    <Tabs
                        defaultActiveKey="home"
                        id="fill-tab-example"
                        className="mb-3"
                        fill
                    >
                        <Tab eventKey="home" title="User">
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>First Name</th>
                                        <th>Last Name</th>
                                        <th>Phone Number</th>
                                        <th>Email</th>
                                        <th>Communication Over Whatsapp</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{user.id}</td>
                                        <td>{user.first_name}</td>
                                        <td>{user.last_name}</td>
                                        <td>{user.phone}</td>
                                        <td>{user.email}</td>
                                        <td>{user.whatsapp ? "Yes" : "No"}</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Tab>
                        <Tab eventKey="Active Policy" title="Active Policies">
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>Purchased Policy ID</th>
                                        <th>Policy Name</th>
                                        <th>Policy Holder</th>
                                        <th>Insurer Name</th>
                                        <th>Paid Amount(Incl. GST)</th>
                                        <th>Sum Insured</th>
                                        <th>Start Date</th>
                                        <th>End Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {activePolicies?.map(
                                        (row: any, index: number) => (
                                            <tr key={row?.policy.id}>
                                                <td>
                                                    {openActivePolicyModal ===
                                                        index ? (
                                                        <>
                                                            {row?.id}
                                                            <img
                                                                className={
                                                                    styles.editIcon
                                                                }
                                                                src={editIcon}
                                                            />
                                                            <UpdatepolicyModalBox
                                                                updatePoliciesTables={() => {
                                                                    fetchPolicy(
                                                                        user.id,
                                                                    );
                                                                }}
                                                                setEditable={() =>
                                                                    setOpenActivePolicyModal(
                                                                        -1,
                                                                    )
                                                                }
                                                                oldStartDate={
                                                                    row?.startDate
                                                                }
                                                                userId={user.id}
                                                                purchasedPolicyId={
                                                                    row?.policy
                                                                        .id
                                                                }
                                                                formSource={
                                                                    'active'
                                                                }
                                                            />
                                                        </>
                                                    ) : (
                                                        <>
                                                            {row?.id}
                                                            <img
                                                                onClick={() =>
                                                                    setOpenActivePolicyModal(
                                                                        index,
                                                                    )
                                                                }
                                                                className={
                                                                    styles.editIcon
                                                                }
                                                                src={editIcon}
                                                            />
                                                        </>
                                                    )}
                                                </td>
                                                <td>{row?.policy?.alias}</td>
                                                <td>
                                                    {row?.policyHolder ?? '-'}
                                                </td>
                                                <td>
                                                    {row?.policy?.insurer ??
                                                        '-'}
                                                </td>
                                                <td>
                                                    {NumberFormat(
                                                        row?.grossPremium ?? 0,
                                                    ) ?? '-'}
                                                </td>
                                                <td>
                                                    {NumberFormat(
                                                        row?.policy
                                                            ?.sumInsured ?? '-',
                                                    ) ?? '-'}
                                                </td>
                                                <td>
                                                    {dateFormat(row?.startDate)}
                                                </td>
                                                <td>
                                                    {dateFormat(row?.endDate)}
                                                </td>
                                            </tr>
                                        ),
                                    )}
                                </tbody>
                            </Table>

                            <Button
                                className="button"
                                variant="primary"
                                style={{ opacity: !openForm ? '100%' : '0%' }}
                                onClick={() => setOpenForm(true)}
                            >
                                Add Policy
                            </Button>

                            {!openForm ? (
                                <></>
                            ) : (
                                <form
                                    className={styles.form}
                                    onSubmit={createPolicyforOfflineUsers}
                                >
                                    <h1 style={{ textAlign: 'center' }}>
                                        Policy Form
                                    </h1>

                                    <label className={styles.required}>
                                        Policy Holder
                                        <input
                                            type="text"
                                            name="sumInsured"
                                            value={policyHolder}
                                            onChange={(e) =>
                                                setpolicyHolder(e.target.value)
                                            }
                                            required
                                        />
                                    </label>

                                    <label className={styles.required}>
                                        Policy Number
                                        <input
                                            type="text"
                                            name="policyNumber"
                                            value={policyNumber}
                                            onChange={(e) =>
                                                setPolicyNumber(e.target.value)
                                            }
                                            required
                                        />
                                    </label>

                                    <label className={styles.required}>
                                        Payment Receipt ID
                                        <input
                                            type="text"
                                            name="receiptId"
                                            value={receiptId}
                                            onChange={(e) =>
                                                setReceiptId(e.target.value)
                                            }
                                            required
                                        />
                                    </label>

                                    <label className={styles.required}>
                                        Start Date
                                        <input
                                            type="date"
                                            name="startDate"
                                            value={startDate}
                                            onChange={(e) =>
                                                setStartDate(e.target.value)
                                            }
                                            required
                                        />
                                    </label>

                                    <label className={styles.required}>
                                        End Date
                                        <input
                                            type="date"
                                            name="endDate"
                                            value={endDate}
                                            onChange={(e) =>
                                                setEndDate(e.target.value)
                                            }
                                            required
                                        />
                                    </label>

                                    <label className={styles.required}>
                                        Sum Insured
                                        <input
                                            type="text"
                                            name="sumInsured"
                                            value={sumInsured}
                                            onChange={(e) =>
                                                setsumInsured(e.target.value)
                                            }
                                            required
                                        />
                                    </label>

                                    <label className={styles.required}>
                                        <select
                                            style={{ width: '100%' }}
                                            value={insurerName || ''}
                                            onChange={handleInsurerName}
                                        >
                                            <option value="">
                                                Choose the Insurer
                                            </option>
                                            {insurerNameArray.map(
                                                (val, index) => (
                                                    <option
                                                        key={index}
                                                        value={val}
                                                    >
                                                        {val}
                                                    </option>
                                                ),
                                            )}
                                        </select>
                                    </label>

                                    <label className={styles.required}>
                                        <select
                                            style={{ width: '100%' }}
                                            value={policyOption || ''}
                                            onChange={handlePolicyName}
                                        >
                                            <option value="">
                                                Choose a Policy
                                            </option>
                                            {optionsArray.map(
                                                (value, index) => (
                                                    <option
                                                        key={index}
                                                        value={value}
                                                    >
                                                        {value}
                                                    </option>
                                                ),
                                            )}
                                        </select>
                                    </label>

                                    {keys.map((key) => (
                                        <label key={key}>
                                            {key}:
                                            <input
                                                type="text"
                                                name={key}
                                                value={insuredPoints[key] || ''}
                                                onChange={(e) =>
                                                    handleInsured(e, key)
                                                }
                                            />
                                        </label>
                                    ))}
                                    <button
                                        type="button"
                                        onClick={handleAddInsueredField}
                                    >
                                        Add Insured Points
                                    </button>

                                    <br></br>

                                    {key.map((key) => (
                                        <label key={key}>
                                            {key}:
                                            <input
                                                type="text"
                                                name={key}
                                                value={
                                                    uninsuredPoints[key] || ''
                                                }
                                                onChange={(e) =>
                                                    handleUninsured(e, key)
                                                }
                                            />
                                        </label>
                                    ))}
                                    <button
                                        type="button"
                                        onClick={handleAddUninsuredField}
                                        className="mt-2"
                                    >
                                        Add UnInsured Points
                                    </button>

                                    <label>
                                        Upload Policy PDF
                                        <input
                                            type="file"
                                            onChange={handleFileChange}
                                            required
                                        />
                                    </label>

                                    {/* <label>
              Limit Insurance
              <input
                type="text"
                name="limitInsurance"
                value={limitInsurance}
                onChange={(e) => setlimitInsurance(e.target.value)}
              />
            </label>

            <label>
              Total Limit
              <input
                type="text"
                name="totallimit"
                value={totalLimit}
                onChange={(e) => settotalLimit(e.target.value)}
              />
            </label>

            <label>
              Sub Limit
              <input
                type="text"
                name="sublimit"
                value={subLimit}
                onChange={(e) => setsubLimit(e.target.value)}
              />
            </label> */}

                                    <Button
                                        variant="primary"
                                        style={{ translate: '250px' }}
                                        type="submit"
                                    >
                                        Submit
                                    </Button>
                                </form>
                            )}
                        </Tab>
                        <Tab eventKey="Pending Policy" title="Pending Policy">
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>Purchased Policy ID</th>
                                        <th>Policy Name</th>
                                        <th>Policy Holder</th>
                                        <th>Insurer Name</th>
                                        <th>Paid Amount(Incl. GST)</th>
                                        <th>Sum Insured</th>
                                        <th>Start Date</th>
                                        <th>End Date</th>
                                        <th>Policy Details</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {pendingPolicies?.map((row: any, index) => (
                                        <tr key={row?.policy.id}>
                                            <td>
                                                {openPendingPolicyModal ===
                                                    index ? (
                                                    <>
                                                        {row?.id}
                                                        <img
                                                            className={
                                                                styles.editIcon
                                                            }
                                                            src={editIcon}
                                                        />
                                                        <UpdatepolicyModalBox
                                                            updatePoliciesTables={() => {
                                                                fetchPolicy(
                                                                    user.id,
                                                                );
                                                            }}
                                                            setEditable={() =>
                                                                setOpenPendingPolicyModal(
                                                                    -1,
                                                                )
                                                            }
                                                            oldStartDate={
                                                                row?.startDate
                                                            }
                                                            userId={user.id}
                                                            purchasedPolicyId={
                                                                row?.policy.id
                                                            }
                                                            formSource={
                                                                'pending'
                                                            }
                                                        />
                                                    </>
                                                ) : (
                                                    <>
                                                        {row?.id}
                                                        <img
                                                            onClick={() =>
                                                                setOpenPendingPolicyModal(
                                                                    index,
                                                                )
                                                            }
                                                            className={
                                                                styles.editIcon
                                                            }
                                                            src={editIcon}
                                                        />
                                                    </>
                                                )}
                                            </td>
                                            <td>{row?.policy?.alias}</td>
                                            <td>{row?.policyHolder ?? '-'}</td>
                                            <td>
                                                {row?.policy?.insurer ?? '-'}
                                            </td>
                                            <td>
                                                {NumberFormat(
                                                    row?.grossPremium ?? 0,
                                                ) ?? '-'}
                                            </td>
                                            <td>
                                                {NumberFormat(
                                                    row?.policy?.sumInsured ??
                                                    '-',
                                                ) ?? '-'}
                                            </td>
                                            <td>
                                                {dateFormat(row?.startDate)}
                                            </td>
                                            <td>{dateFormat(row?.endDate)}</td>
                                            <td>
                                                {
                                                    (row?.policy?.coverName.includes('Fire') || row?.policy?.coverName.includes('Stock')) && <img
                                                        onClick={() =>
                                                            handlePolicyDetailsOpen(
                                                                row.policy.id,
                                                            )
                                                        }
                                                        className={styles.editIcon}
                                                        src={viewIcon}
                                                    />
                                                }
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                                {viewPolicyDetailsId && (
                                    <PolicyDetailsModal
                                        show={showPolicyDetailsModal}
                                        policyId={viewPolicyDetailsId}
                                        onHide={
                                            handlePolicyDetailsModalClose
                                        }
                                    />
                                )}
                            </Table>
                        </Tab>
                        <Tab eventKey="Payments" title="Payments">
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>Receipt ID</th>
                                        <th>Merchant Transaction ID</th>
                                        <th>UTR Number</th>
                                        <th>Transaction Ref ID</th>
                                        <th>Basic Amount</th>
                                        <th>Tax on Premium</th>
                                        <th>Gross Premium</th>
                                        <th>Payment Date</th>
                                        <th>Payment Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {payments?.map((row: any, index) => (
                                        <tr key={row?.receiptId}>
                                            <td>{row?.receiptId}</td>
                                            <td>{row?.orderId}</td>
                                            <td>{row?.utrNumber}</td>
                                            <td>{row?.transactionId}</td>
                                            <td>
                                                {NumberFormat(
                                                    row?.basicAmount ?? 0,
                                                ) ?? '-'}
                                            </td>
                                            <td>
                                                {NumberFormat(row?.tax ?? 0) ??
                                                    '-'}
                                            </td>
                                            <td>
                                                {NumberFormat(
                                                    row?.grossAmount ?? 0,
                                                ) ?? '-'}
                                            </td>
                                            <td>
                                                {dateFormat(row?.paymentDate)}
                                            </td>
                                            <td>{capitalizeFirstLetter(row?.paymentStatus)}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                            <Button
                                className="button"
                                variant="primary"
                                style={{
                                    opacity: !openPaymentsForm ? '100%' : '0%',
                                }}
                                onClick={() => setOpenPaymentsForm(true)}
                            >
                                Add a Payment
                            </Button>

                            {!openPaymentsForm ? (
                                <></>
                            ) : (
                                <form
                                    className={styles.form}
                                    onSubmit={createPaymentforOfflineUsers}
                                >
                                    <h1 style={{ textAlign: 'center' }}>
                                        Add Payment
                                    </h1>

                                    <label className={styles.required}>
                                        Basic Premium
                                        <input
                                            type="text"
                                            name="basicAmount"
                                            value={basicAmount}
                                            onChange={(e) =>
                                                setBasicAmount(e.target.value)
                                            }
                                            required
                                        />
                                    </label>

                                    <label className={styles.required}>
                                        Tax on Premium
                                        <input
                                            type="text"
                                            name="tax"
                                            value={tax}
                                            onChange={(e) =>
                                                setTax(e.target.value)
                                            }
                                            required
                                        />
                                    </label>

                                    <label className={styles.required}>
                                        Gross Premium
                                        <input
                                            type="text"
                                            name="grossAmount"
                                            value={grossAmount}
                                            onChange={(e) =>
                                                setGrossAmount(e.target.value)
                                            }
                                            required
                                        />
                                    </label>

                                    <label className={styles.required}>
                                        Payment Date
                                        <input
                                            type="date"
                                            name="paymentDate"
                                            value={paymentDate}
                                            onChange={(e) =>
                                                setPaymentDate(e.target.value)
                                            }
                                            required
                                        />
                                    </label>

                                    <Button
                                        variant="primary"
                                        // style={{ translate: '250px' }}
                                        type="submit"
                                    >
                                        Submit
                                    </Button>
                                </form>
                            )}
                        </Tab>
                        <Tab eventKey="SupportTickets" title="Support Tickets">
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>Message</th>
                                        <th>Question</th>
                                        <th>Created At</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {supportTickets?.map((row: any, index) => (
                                        <tr key={row.id}>
                                            <td>{row.message}</td>
                                            <td>{row.question}</td>
                                            <td>{dateFormat(row.createdAt)}</td>
                                            <td>
                                                {editSupportTicketStatusOption ===
                                                    index ? (
                                                    <div
                                                        className={
                                                            styles.quoteUpdate
                                                        }
                                                    >
                                                        <SupportTicketStatusUpdate
                                                            selectedOption={
                                                                row.status
                                                            }
                                                            setSelectedOption={
                                                                setSelectedOption
                                                            }
                                                        />
                                                        <div
                                                            onClick={() =>
                                                                handleTickClick(
                                                                    row.id,
                                                                )
                                                            }
                                                        >
                                                            ✅
                                                        </div>
                                                        <div
                                                            onClick={
                                                                handleCrossClick
                                                            }
                                                        >
                                                            ❌
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <>
                                                        {row.status}
                                                        <img
                                                            onClick={() =>
                                                                setEditSupportTicketStatusOption(
                                                                    index,
                                                                )
                                                            }
                                                            className={
                                                                styles.editIcon
                                                            }
                                                            src={editIcon}
                                                        />
                                                    </>
                                                )}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </Tab>
                    </Tabs>
                </div>
            )}
        </div>
    );
};

export default FormComponent;
